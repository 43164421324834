import moment from 'moment';
import { getDaysDistanceFromToday } from '@common/utils/time';
import {
  APIEmployeeAcademyCourseOverview, APIEmployeeAcademyCourseResponse
} from '@modules/learning/types/objects';

export const DEFAULT_COURSE_IMAGE = '/static/images/event-placeholder.png';

export const getFirstAvailableCourse = (courses: APIEmployeeAcademyCourseOverview[]) => {
  return courses.find((course) => {
    return course.availability.locked === false && course.progress.finished === false;
  });
};

export const getFirstAvailableModuleUrl = (course: APIEmployeeAcademyCourseResponse, url: string) => {
  for (const section of (course?.data?.sections || [])) {
    for (const module of (section?.modules || [])) {
      if (module?.progress?.finished === false && module?.availability?.locked === false) {
        return `${url}/module/${module.id}/1`;
      }
    }
  }
  return null;
};

const getSortedByDeadline = (courses: APIEmployeeAcademyCourseOverview[]) => {
  const now = Date.now();
  // sort() sorts in-place so we need to clone the array
  return [...courses].sort((courseA, courseB) => {
    const progressA = courseA.progress.finished_module_count / courseA.progress.module_count;
    const progressB = courseB.progress.finished_module_count / courseB.progress.module_count;
    if (progressA > progressB) {
      return -1; // A has more progress, it comes first
    }
    if (progressB > progressA) {
      return 1; // B has more progress
    }
    return 0; // equal progress, don't change order
  }).sort((courseA, courseB) => {
    const deadlineA = courseA.progress.deadline && new Date(courseA.progress.deadline);
    const deadlineB = courseB.progress.deadline && new Date(courseB.progress.deadline);
    if (deadlineA && deadlineB) {
      const timeToA = deadlineA.getTime() - now;
      const timeToB = deadlineB.getTime() - now;

      // same position in time, here we leave the previous sorting by progress intact
      if (timeToA === timeToB) {
        return 0;
      }

      if (Math.abs(timeToA) < Math.abs(timeToB)) { // A is closer to now
        // if B is in the future and A in the past
        if (deadlineB.getTime() > now && deadlineA.getTime() < now) {
          return 1; // B comes first
        }
        return -1; // A comes first
      }
      // B is closed to now
      // but if A is in the future and B in the past
      if (deadlineA.getTime() > now && deadlineB.getTime() < now) {
        return -1; // A comes first
      }
      return 1; // B is closer to now, it comes first
    }
    if (deadlineA) return -1; // A has deadline and B doesn't, A comes first
    if (deadlineB) return 1; // B has deadline and A does not, B comes first;
    return 0;
  });
};

const getExpirationLabel = (locale: string, date?: string | null) => {
  if (!date) return null;

  const daysDiff = getDaysDistanceFromToday(date);

  if (daysDiff === 0) {
    return { i18nKey: 'dates:today' };
  }
  if (daysDiff === 1) {
    return { i18nKey: 'dates:tomorrow' };
  }

  const now = moment();
  const localisedDate = moment(date).locale(locale);

  if (daysDiff < 0) {
    if (daysDiff === -1) {
      return { i18nKey: 'dates:yesterday' };
    }
    if (localisedDate.isSame(now, 'year')) {
      return localisedDate.format('D MMMM');
    }
    return localisedDate.format('D MMMM YYYY');
  }

  if (daysDiff < 7) { // within the future week
    return localisedDate.format('dddd');
  }
  if (localisedDate.isSame(now, 'year')) {
    return localisedDate.format('D MMMM');
  }

  return localisedDate.format('D MMMM YYYY');
};

const getExpirationClassName = (dueDate?: string | null) => {
  if (!dueDate) return '';
  const dueDateObj = new Date(dueDate);
  const now = new Date();
  if (dueDateObj.getTime() < now.getTime()) {
    return 'statusLabel--expired';
  }
  return 'statusLabel--open';
};

export const getStatusData = (
  locale: string,
  dueDate?: string | null,
  completed?: boolean | null
) => {
  if (completed) {
    return {
      label: { i18nKey: 'learning:onboarding_statistics_column_label_finished' },
      className: 'statusLabel--completed'
    };
  }
  return {
    label: getExpirationLabel(locale, dueDate),
    className: getExpirationClassName(dueDate)
  };
};

export const getCompletedCoursesProgress = (courses: APIEmployeeAcademyCourseOverview[]) => {
  if (!courses || courses.length <= 0) {
    return 0;
  }
  const finishedCourses = courses.filter((course) => course.progress.finished);
  return finishedCourses.length / courses.length;
};

export const getCoursesToContinue = (courses: APIEmployeeAcademyCourseOverview[]) => {
  return getSortedByDeadline(
    courses.filter((course) => {
      return course.progress.current_points > 0 && !course.progress.finished;
    })
  );
};

export const getCoursesWithDeadline = (courses: APIEmployeeAcademyCourseOverview[]) => {
  return getSortedByDeadline(
    courses.filter((course) => {
      return (
        course.progress.deadline && !course.progress.finished &&
        course.progress.current_points <= 0
      );
    })
  );
};

export const getCoursesToDiscover = (courses: APIEmployeeAcademyCourseOverview[]) => {
  return courses.filter((course) => {
    const { current_points, finished, deadline } = course.progress;
    return current_points <= 0 && !finished && !deadline;
  });
};

export const getCoursesCompleted = (courses: APIEmployeeAcademyCourseOverview[]) => {
  return courses.filter((course) => course.progress.finished);
};
