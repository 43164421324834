import React, { memo, useState } from 'react';
import { Route, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import CommentsModal from './comments-modal';
import { Button } from '@common/components/button';
import './comments-icon.scss';

type CommentsIconProps = {
  submissionId: string;
  organisationId: string;
  commentsCount: number | undefined;
};

const CommentsIcon = memo(({
  submissionId, organisationId, commentsCount
}: CommentsIconProps) => {
  const match = useRouteMatch();

  const [count, setCount] = useState(commentsCount);

  return (
    <>
      <Link to={`${match.url}/comments`} className="commentsIcon">
        <Button className="commentsTrigger" size="large" icon="chat__filled" />
      </Link>
      {
        typeof commentsCount === 'number' && (
          <Link to={`${match.url}/comments`} className="commentsCount">
            <span>{ count }</span>
          </Link>
        )
      }
      <Route path={`${match.path}/comments`}>
        <CommentsModal
          submissionId={submissionId}
          organisationId={organisationId}
          setCommentsCount={setCount}
        />
      </Route>
    </>
  );
});

export default CommentsIcon;
