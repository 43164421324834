import Api from '@common/services/api';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Placeholder from '@common/components/placeholder';
import AsyncTable from '@common/components/table';
import FormCell from './form-cell';

export const generateManagedByMeUrl = (orgId: string, query: any) => {
  return `/v1/organisations/${orgId}/forms/moderators/me?${query}`;
};

type FormsManagedByMeTableProps = {
  orgId: string;
  baseUrl: string;
};

const FormsManagedByMeTable = memo(({
  orgId,
  baseUrl
}: FormsManagedByMeTableProps) => {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [{
      label: t('forms:overview_label_name'),
    }, {
      label: t('forms:overview_label_submissions'),
    }, {
      label: t('forms:overview_label_created_at'),
    }];
  }, [t]);

  const data = useMemo(() => {
    return {
      useCursor: true,
      onFetch: (
        nextCursor: string | null,
        filter: any,
        limit = 20
      ) => {
        const query = Api.utils.toQuery({
          limit,
          cursor: nextCursor || true,
        });
        return Api.get(generateManagedByMeUrl(orgId, query));
      },
      filter: {},
    };
  }, [orgId]);

  return (
    // @ts-expect-error
    <AsyncTable
      columns={columns}
      data={data}
      renderRow={({ item }: any) => {
        // console.log('debug item', item);
        return [
          <FormCell
            href={`${baseUrl}/${item.id}/submissions`}
            form={item}
          />,
          item.submissions_count,
          moment(item.created_at).format('MMM D, YYYY')
        ];
      }}
      placeholder={(
        <Placeholder title={t('forms:no_forms_placeholder')} />
      )}
    />
  );
});

export default FormsManagedByMeTable;
