// just a plain search component without all the form stuff pollution that's in search-bar.tsx
// we should probably deprecate search-bar and opt with this input instead, create wrappers around it
// to add the form stuff where needed.

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../icon';

type SearchProps = {
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Search = memo(({ placeholder, value, onChange }: SearchProps) => {
  const { t } = useTranslation();
  return (
    <div role="search" className="Form SearchBar">
      <Icon type="search" />
      <input
        className="Form__control"
        value={value}
        onChange={onChange}
        placeholder={placeholder || t('common:search_bar_placeholder')}
      />
    </div>
  );
});

export default Search;
