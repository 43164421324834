import { useMemo } from 'react';
import { selected } from '@modules/organisation/selectors/organisation';
import { Network } from '@common/types/objects';
import { useAppSelector } from '../redux';

const useNetworkComponentCheck = (
  componentToCheck: 'FLEXCHANGE' | 'SCHEDULE',
  networks: Network[]
) => {
  const networkEnabledComponents = useMemo(() => {
    return networks.map((n) => n.enabled_components).filter((enComps) => !!enComps).flat();
  }, [networks]);

  const { available_components: orgAvailableComponents } = useAppSelector(selected);

  return useMemo(() => {
    return (
      orgAvailableComponents.indexOf(componentToCheck) > -1 &&
      networkEnabledComponents.indexOf(componentToCheck) > -1
    );
  }, [orgAvailableComponents, networkEnabledComponents, componentToCheck]);
};

export default useNetworkComponentCheck;
