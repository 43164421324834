import React, { memo, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import Container from '@common/components/container';
import { Overview } from '@common/components/overview';
import { TopNavigationBar } from '@common/components/navigation-bar';
import { APIEmployeeAcademyCourseResponse } from '@modules/learning/types/objects';
import Spinner from '@common/components/spinner';
import EmployeeAcademyHome from './home/employee-academy-home';
import CourseOverview from './course-overview/course-overview';
import ModuleSubmissionEditor from './module-submission-editor/module-submission-editor';
import { useEmployeeAcademyBreadcrumbs } from '@modules/learning/hooks/employee-academy';
import './employee-academy.scss';

type EmployeeAcademyProps = {
  networkId: string;
};

const EmployeeAcademy = memo(({ networkId }: EmployeeAcademyProps) => {

  const { t } = useTranslation();

  const [course, setCourse] = useState<APIEmployeeAcademyCourseResponse | null>(null);

  const { path } = useRouteMatch<{ networkId: string }>();

  const courseDetailPagePath = `${path}/course/:courseId`;
  const courseMatch = useRouteMatch<{ courseId: string }>({
    path: courseDetailPagePath,
    exact: true
  });

  const courseLoading = courseMatch?.params.courseId && !course;

  const breadcrumbs = useEmployeeAcademyBreadcrumbs(course, networkId);

  const getTitle = () => {
    if (courseLoading) {
      return <Spinner size="small" />;
    }
    return course?.data?.name || t('core:tab_elearning');
  };

  return (
    <Switch>

      <Route path={`${path}/course/:courseId/module/:moduleId/:page`} exact>
        <ModuleSubmissionEditor networkId={networkId} course={course} setCourse={setCourse} />
      </Route>

      <Route>
        <Container name="EmployeeAcademy">
          <TopNavigationBar breadcrumbs={breadcrumbs} title={getTitle()} />
          <Container.Content>
            <Overview>
              <Switch>

                <Route path={courseDetailPagePath} exact>
                  <CourseOverview course={course} setCourse={setCourse} />
                </Route>

                <Route path={path}>
                  <EmployeeAcademyHome />
                </Route>

              </Switch>
            </Overview>
          </Container.Content>
        </Container>
      </Route>
    </Switch>
  );
});

export default EmployeeAcademy;
