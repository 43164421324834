export const getMaxScrollRight = (elem: HTMLElement) => {
  // @ts-expect-error
  if (typeof elem.scrollLeftMax === 'number') {
    // @ts-expect-error
    return elem.scrollLeftMax;
  }
  return (elem.scrollWidth - elem.clientWidth);
};

export const hasOverflowRight = (elem: HTMLElement) => {
  return elem.scrollWidth > elem.clientWidth;
};
