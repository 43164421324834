import React, { memo, useCallback } from 'react';
import { ReceivedQuestionComponentText } from '@modules/learning/types/objects';
import {
  FormSubmissionValues, SetFormSubmissionValues
} from '@modules/forms/types';
import { QuestionMultipleChoiceScreenComponent } from '../components/question';

type QuestionValue = string[];

type QuestionProps = {
  item: ReceivedQuestionComponentText;
  setValues: SetFormSubmissionValues;
  value: QuestionValue;
  readOnly?: boolean;
  outcomePhase?: boolean;
  showCorrectAnswers?: boolean;
};

const Question = memo(({
  item, setValues, value, outcomePhase, showCorrectAnswers, readOnly = false
}: QuestionProps) => {

  const onChange = useCallback((newValue: QuestionValue) => {
    setValues((values: FormSubmissionValues | null) => {
      return {
        ...values,
        [item.id]: newValue
      };
    });
  }, [setValues, item]);

  return (
    <QuestionMultipleChoiceScreenComponent
      item={item}
      onChange={readOnly ? undefined : onChange}
      value={value}
      outcomePhase={outcomePhase}
      showCorrectAnswers={showCorrectAnswers}
    />
  );
});

export default Question;
