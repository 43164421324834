import React, { MouseEventHandler } from 'react';
import moment from 'moment';
import Markdown from 'react-markdown';
import ProfileImage from '@common/components/profile-image';
import Icon from '../../../../common/components/icon';
import { ESourceTypes } from '../../../../common/definitions';
import type { IconTypes } from '@common/components/icon';
import type { Activity, ActivityType } from '../../../../common/types/objects';

const colorLookup = {
  [ESourceTypes.MESSAGE]: '#19214D',
  [ESourceTypes.EXCHANGE]: '#FF9500',
  [ESourceTypes.CONVERSATION]: '#4CD964',
  [ESourceTypes.ONBOARDING]: '#FFCC00',
  [ESourceTypes.COURSE]: '#FFCC00',
  [ESourceTypes.EVENT]: '#FF2D55',
  [ESourceTypes.FORM_SUBMISSION]: '#5856D6',
  [ESourceTypes.SURVEY]: '#30B0C7'
};

const iconLookup = (activityType: ActivityType) => {
  switch (activityType) {
    case 'survey_reminder':
    case 'survey_published':
      return {
        icon: 'check_circle',
        filled: true
      };
    case 'comment_created':
    case 'exchange_comment_created':
    case 'event_message_created':
      return { icon: 'chat__filled' };
    case 'exchange_created':
    case 'exchange_accepted':
    case 'exchange_approved':
      return { icon: 'sync_alt' };
    case 'added_to_groupchat':
      return { icon: 'group_add__filled' };
    case 'highfive_added':
    case 'highfive_added_for_mention':
      return { icon: 'highfive__filled' };
    case 'welcome_message_created':
      return { icon: 'person_add__filled' };
    case 'birthdays_today':
      return { icon: 'cake__filled' };
    case 'new_onboarding_category_available':
    case 'new_course':
    case 'course_almost_due':
    case 'onboarding_reminder':
      return { icon: 'school__filled' };
    case 'event_users_invited':
    case 'event_availability_reminder':
      return { icon: 'event__filled' };
    case 'form_submission_status_updated':
    case 'form_submission_created':
      return { icon: 'assignment' };
    default:
      return { icon: 'notifications__filled' };
  }
};

type Props = {
  item: Activity;
  onNotificationClick: (item: Activity) => MouseEventHandler<HTMLDivElement> | undefined;
};

const NotificationListItem = ({ item, onNotificationClick = () => undefined }: Props) => {
  // Check if onClick is present, if so returns callback function
  const onClick = onNotificationClick(item);

  let containerClassName = 'Notifications__Item';
  containerClassName += ` Notifications__Item--${item.seen ? 'read' : 'unread'}`;
  if (onClick) containerClassName += ' Notifications__Item--clickable';

  const { icon, filled } = iconLookup(item.activity_type);

  return (
    <div
      className={containerClassName}
      onClick={onClick}
      role="link"
    >
      <div className="Notifications__Item__Image">
        {
          item.source_type === ESourceTypes.COURSE ? (
            <div>
              <img src="/static/images/course-placeholder.png" alt="CoursePlaceholder" />
            </div>
          ) : (
            <ProfileImage user={item.user!} size={48} />
          )
        }
        <Icon
          type={icon as IconTypes}
          style={{ backgroundColor: colorLookup[item.source_type] }}
          isFilled={!!filled}
        />
      </div>

      <div className="Notifications__Item__Text">
        <Markdown>{item.text}</Markdown>
        <small>{moment(item.created_at).calendar()}</small>
      </div>
    </div>
  );
};

export default NotificationListItem;
