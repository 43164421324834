import ModalContent from '@common/components/modal/modal-content';
import ModalWindow from '@common/components/modal/modal-window';
import React, {
  Dispatch, SetStateAction, memo, useCallback, useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import PostComment from './post-comment';
import { useComments } from './utils';
import '../../../../social/components/comment/styles.scss';
import './comments-modal.scss';
import SubmissionCommentsList from './submission-comments-list';

type CommentsModalProps = {
  submissionId: string;
  organisationId: string;
  setCommentsCount: Dispatch<SetStateAction<number | undefined>>;
};

const CommentsModal = memo(({
  organisationId,
  submissionId,
  setCommentsCount
}: CommentsModalProps) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const onHide = useCallback(() => {
    const directories = url.split('/');
    directories.pop();
    history.push(directories.join('/')); // url is the path of the upper level directory route
  }, [url, history]);

  const { t } = useTranslation();

  const {
    comments,
    getComments,
    addComment,
    onDelete,
    submissionConfig
  } = useComments(organisationId, submissionId);

  useEffect(() => {
    if (!comments) return;
    const count = comments.filter((comment) => !comment.deleted_at).length;
    setCommentsCount(count);
  }, [comments, setCommentsCount]);

  useEffect(() => {
    if (!comments) return;
    // let's set the scroll view to the bottom so the user sees the last comments
    // as soon as the comments are ready
    setTimeout(() => {
      const commentsModal = document.getElementsByClassName('CommentsModal')[0];
      const modalBody = commentsModal.getElementsByClassName('modal-body')[0];
      modalBody.scrollTop = modalBody.scrollHeight;
    }, 0);
  }, [comments]);

  return (
    <ModalWindow
      size="small"
      className="Message__comments CommentsModal"
      show
      onHide={onHide}
    >
      <ModalContent
        title={t('core:comments')}
        onHide={onHide}
        customFooter={(
          <PostComment
            organisationId={organisationId}
            submissionId={submissionId}
            addComment={addComment}
          />
        )}
      >
        <SubmissionCommentsList
          getComments={getComments}
          comments={comments}
          submissionConfig={submissionConfig}
          onDelete={onDelete}
        />
      </ModalContent>
    </ModalWindow>
  );
});

export default CommentsModal;
