import React, { memo, useMemo } from 'react';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';
import { combineClassNames } from '@common/utils/combineClassNames';
// @ts-expect-error
import KpiBackground from '../../../../../../assets/illustrations/kpi-background.svg';
// @ts-expect-error
import Medal from '../../../../../../assets/illustrations/medal.svg';
import './module-results-cockade.scss';

// 100% value must not render a full circle, as there's a gap between the beginning of the curve and the end
const MAX_VALUE = 0.8325;
const OUTER_RADIUS = 115;
const DIAMETER = OUTER_RADIUS * 2;
const PROGRESS_THICKNESS = 16;

type ModuleResultsCockadeProps = {
  achievedPoints: number;
  maxPoints: number;
  success: boolean;
};

const ModuleResultsCockade = memo(({
  achievedPoints,
  maxPoints,
  success
}: ModuleResultsCockadeProps) => {

  const progress = achievedPoints / maxPoints;

  const progressData = useMemo(() => {
    return [
      {
        name: 'progress-line',
        value: progress * MAX_VALUE,
      },
      {
        name: 'placeholder-hidden-by-css',
        value: 1 - MAX_VALUE + (1 - progress) * MAX_VALUE,
      }
    ];
  }, [progress]);

  const className = combineClassNames('ModuleResultsCockade', {
    'ModuleResultsCockade--failed': !success
  });

  return (
    <div className={className} style={{ height: `${DIAMETER}px` }}>
      <KpiBackground />
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={DIAMETER} height={DIAMETER}>
          <Pie
            data={progressData}
            startAngle={-59.5}
            endAngle={299.5}
            cx="50%"
            cy="50%"
            innerRadius={OUTER_RADIUS - PROGRESS_THICKNESS}
            cornerRadius={PROGRESS_THICKNESS}
            outerRadius={OUTER_RADIUS}
            dataKey="value"
          />
        </PieChart>
      </ResponsiveContainer>
      <Medal className="ModuleResultsCockade__Medal" />
      <h4 className="ModuleResultsCockade__Points">
        <span className="ModuleResultsCockade__AchievedPoints">{achievedPoints}/</span>
        {` ${maxPoints}`}
      </h4>
    </div>
  );
});

export default ModuleResultsCockade;
