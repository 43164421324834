import { combineClassNames } from '@common/utils/combineClassNames';
import React, { ComponentType, memo, useMemo } from 'react';

export type ProportionChartBar = {
  color: string;
  value: number;
  wrapper?: ComponentType;
  key?: string | number;
};

type ProportionChartProps = {
  bars: ProportionChartBar[];
  className?: string;
  vertical?: boolean;
  height?: number;
};

const sum = (a: number, b: number) => a + b;

const FULL_SIZE = 100;

const ProportionChart = memo(({
  bars,
  className,
  vertical = false,
  height = 150
}: ProportionChartProps) => {

  /* you can change the width (or height if vertical) of the .ProportionChart
  node via CSS and the size proportions will be respected anyways */
  const sizePercentages = useMemo(() => {
    const values = bars.map((bar: ProportionChartBar) => bar.value);
    const total = values.reduce(sum, 0);

    const sizes = [];
    for (let i = 0; i < values.length; i++) {
      let size: number;
      if ((i + 1) === values.length) {
        size = (FULL_SIZE - sizes.reduce(sum, 0));
      } else {
        size = ((values[i] / total) * FULL_SIZE);
      }
      sizes.push(size);
    }

    return sizes;
  }, [bars]);

  return (
    <div
      className={
        combineClassNames(
          'ProportionChart',
          vertical ? 'vertical' : 'horizontal',
          className
        )
      }
      style={{
        height: vertical ? height : undefined
      }}
    >
      {
        bars.map(({ color, wrapper, key }: ProportionChartBar, index: number) => {
          const bar = (
            <div
              key={key || color}
              style={{
                [vertical ? 'height' : 'width']: `${sizePercentages[index]}%`,
                backgroundColor: color
              }}
            />
          );
          const Wrapper = wrapper;
          if (Wrapper) {
            return <Wrapper key={`Wrapper_${key || color}`}>{ bar }</Wrapper>;
          }
          return bar;
        })
      }
    </div>
  );
});

export default ProportionChart;
