import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useAppSelector } from '@common/hooks';
import { selected } from '@modules/organisation/selectors/organisation';
import Api from '@common/services/api';
import { ApiResponse } from '@common/services/api/types';
import { EmployeeSurveyDetail } from '@modules/survey/types/objects';
import { getInitialValues } from '@common/components/form/user-response-fields/utils';
import { FormSubmissionValues } from '@modules/forms/types';
import { useQueryParam } from '@common/hooks/url';
import SubmissionEditorSteps from '@common/components/submission-editor/submission-editor-steps';
import SubmissionEditor from '@common/components/submission-editor/submission-editor';
import SubmissionEditorTopbar from '@common/components/submission-editor/submission-editor-topbar';
import NextButton from './next-button';
import { useSurveyEditorErrorHandling } from './utils';
import AnonymityBanner from './anonymity-banner';

import './survey-submission-editor.scss';

type SurveyResponse = ApiResponse<EmployeeSurveyDetail>;

type RouteParams = {
  surveyId: string;
  networkId: string;
  page: string;
};

const SurveySubmissionEditor = memo(() => {
  const { surveyId, networkId, page } = useRouteMatch<RouteParams>().params;

  // origin will be a url string if the user arrived to the editor by
  // clicking on an activity notification
  const origin = useQueryParam('origin');

  const orgId = useAppSelector(selected).id;

  const [survey, setSurvey] = useState<SurveyResponse | null>(null);

  const [values, setValues] = useState<null | FormSubmissionValues[]>(null);

  const timelinePath = `/networks/${networkId}/feed`;
  const editorCloseUrl = origin || timelinePath;
  const handleError = useSurveyEditorErrorHandling(editorCloseUrl);

  useEffect(() => {
    const path = `/v1/organisations/${orgId}/users/me/surveys/${surveyId}`;
    Api.get<SurveyResponse>(path).then((response) => {
      setValues(getInitialValues(response.data.screens));
      setSurvey(response);
    }).catch(handleError);
  }, [orgId, surveyId, setSurvey, setValues, handleError]);

  const { t } = useTranslation();
  const firstStepUrl = `/networks/${networkId}/survey/${surveyId}/1`;
  const breadcrumbs = useMemo(() => {
    const timeline = {
      name: t('social:channels_my_timeline'),
      path: timelinePath
    };
    if (!survey) {
      return [timeline];
    }
    return [
      timeline,
      {
        name: survey.data.title,
        path: firstStepUrl
      }
    ];
  }, [t, survey, timelinePath, firstStepUrl]);

  const currentPage = parseInt(page, 10);

  const stepsTotal = survey?.data?.screens?.length || 0;

  const ready = !!(values && survey);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const nextBtn = ready && (
    <NextButton
      survey={survey.data}
      networkId={networkId}
      currentPage={currentPage}
      values={values}
      orgId={orgId}
      submitting={submitting}
      setSubmitting={setSubmitting}
    />
  );

  return (
    <SubmissionEditor
      loading={!ready}
      className="SurveySubmissionEditor"
      messageOnLeave={t('survey:submission_editor_leave_confirm')}
    >
      <SubmissionEditorTopbar
        title={survey?.data?.title}
        breadcrumbs={breadcrumbs}
        stepsTotal={stepsTotal}
        editorCloseUrl={editorCloseUrl}
        backButtonText={origin ? t('common:back') : t('common:close')}
        nextBtn={nextBtn}
      />
      <SubmissionEditorSteps
        screens={survey?.data.screens}
        values={values || []}
        setValues={setValues}
        className={
          survey?.data?.settings?.is_anonymous ?
            'FormSubmissionEditorContent--is_anonymous' :
            undefined
        }
      />
      { nextBtn }
      { survey?.data?.settings?.is_anonymous && <AnonymityBanner /> }
    </SubmissionEditor>
  );
});

export default SurveySubmissionEditor;
