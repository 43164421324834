import React, { PropsWithChildren, memo } from 'react';
import useNetworkComponentCheck from '@common/hooks/permissions/use-network-component-check';
import { Network } from '@common/types/objects';
import { usePermission } from '@common/hooks';
import { Permissions } from '@common/definitions';

type NetworkComponentPermissionProps = PropsWithChildren<{
  component: 'FLEXCHANGE' | 'SCHEDULE';
  networks: Network[];
  permissions?: Permissions[];
}>;

const NetworkComponentPermission = memo(({
  component,
  networks,
  permissions,
  children
}: NetworkComponentPermissionProps) => {

  const shouldCheckPermissions = !!permissions;
  const permissionsOK = usePermission(permissions || []);

  const componentOK = useNetworkComponentCheck(component, networks);

  if (componentOK && (shouldCheckPermissions ? permissionsOK : true)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{ children }</>;
  }
  return null;
});

export default NetworkComponentPermission;
