import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useCurrentPage, useFieldValue } from '@common/components/submission-editor/utils';
import { FormSubmissionValues } from '@modules/forms/types';
import { Button } from '@common/components/button';
import { APIEmployeeAcademyCourseModule } from '@modules/learning/types/objects';
import {
  useCurrentScreenErrors,
  useModuleCorrectAnswers,
  useModuleNextScreenUrl,
  useQuestionComponent
} from '@modules/learning/hooks/employee-academy';
import OutcomePhaseNextButton from './outcome-phase-next-button';

import './next-screen-button.scss';

type NextScreenButtonProps = {
  values: FormSubmissionValues[];
  module: APIEmployeeAcademyCourseModule;
  setOutcomePhase: (outcomePhase: boolean) => void;
  outcomePhase: boolean;
};

const NextScreenButton = memo(({
  values,
  module,
  setOutcomePhase,
  outcomePhase
}: NextScreenButtonProps) => {

  const { t } = useTranslation();
  const currentPage = useCurrentPage();
  const nextScreenUrl = useModuleNextScreenUrl(values.length);

  const errors = useCurrentScreenErrors(module, values);

  const moduleCorrectAnswers = useModuleCorrectAnswers(module);
  const questionComponent = useQuestionComponent(module);
  const value = useFieldValue(values, questionComponent?.id) as string[] | null;

  const isLastScreen = currentPage >= values.length;

  if (outcomePhase) {
    const gotErrors = errors.length > 0;
    return (
      <OutcomePhaseNextButton
        module={module}
        values={values}
        setOutcomePhase={setOutcomePhase}
        className="NextScreenButton nextButton"
      >
        {
          `${
            gotErrors ?
              t('learning:form_question_feedback_incorrect') :
              t('learning:form_question_feedback_correct')
          }. ${
            isLastScreen ? t('common:complete') : t('common:continue')
          }`
        }
      </OutcomePhaseNextButton>
    );
  }

  const isQuestionScreen = !!questionComponent;
  // if the question allows multiple answers, we require at least 2 answers to enable the next button
  // otherwise 1 is enough to go to the next screen
  const minimumRequiredAnswers = moduleCorrectAnswers.length > 1 ? 2 : 1;
  const hasEnoughInput = (value?.length || 0) >= minimumRequiredAnswers;
  const nextDisabled = isQuestionScreen && !hasEnoughInput;

  const button = (
    <Button type="primary" size="large" disabled={nextDisabled}>
      { isLastScreen ? t('common:complete') : t('common:continue') }
    </Button>
  );

  if (nextDisabled) {
    return (
      <div className="NextScreenButton nextButton">
        { button }
      </div>
    );
  }

  if (isQuestionScreen && hasEnoughInput) {
    return (
      <div className="NextScreenButton nextButton" onClick={() => setOutcomePhase(true)}>
        { button }
      </div>
    );
  }

  return (
    <Link className="NextScreenButton nextButton" to={nextScreenUrl!}>
      { button }
    </Link>
  );
});

export default NextScreenButton;
