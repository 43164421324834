import React, { memo } from 'react';
import { FormIcon } from '../form-icon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type FormCellProps = {
  form: any;
  href: string;
};

const FormCell = memo(({ form, href }: FormCellProps) => {
  const { t } = useTranslation();
  return (
    <div className="Table__Cell__Title FormTitle">
      <FormIcon form={form} />
      <Link role="button" to={href}>
        { form.title || <small>{t('forms:form_item_no_title')}</small> }
      </Link>
    </div>
  );
});

export default FormCell;
