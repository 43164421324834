import React, { memo } from 'react';
import { APIEmployeeAcademyCourseResponse } from '@modules/learning/types/objects';
import { OverviewContent } from '@common/components/overview';
import Spinner from '@common/components/spinner';
import CourseSummary from './course-summary';
import CourseSection from './course-section';
import { useEmployeeCourse } from '@modules/learning/hooks/employee-academy';
import './course-overview.scss';

type CourseOverviewProps = {
  course: APIEmployeeAcademyCourseResponse | null;
  setCourse: (course: APIEmployeeAcademyCourseResponse | null) => void;
};

const CourseOverview = memo(({ course, setCourse }: CourseOverviewProps) => {

  useEmployeeCourse(setCourse);

  return (
    <OverviewContent className="CourseOverview">
      {
        course ?
          (
            <>
              <CourseSummary course={course} />
              <div className="CourseOverview__Modules">
                {
                  course.data.sections.map((section) => (
                    <CourseSection key={section.id} section={section} />
                  ))
                }
              </div>
            </>
          ) :
          (
            <div className="EmployeeAcademy__Loading">
              <Spinner size="large" />
            </div>
          )
      }
    </OverviewContent>
  );
});

export default CourseOverview;
