import React, { memo } from 'react';
import VideoPreview from '@common/components/video';
import { ScreenVideoItem } from '@modules/learning/types/objects';

type VideoProps = {
  item: ScreenVideoItem;
};

const Video = memo(({ item }: VideoProps) => {
  const attachment = item?.parameters?.attachment;
  const path = attachment?.path;
  const thumb = attachment?.thumb_path;
  return (
    <VideoPreview id={`editor-video-${item?.id}`} path={path} thumb={thumb} />
  );
});

export default Video;
