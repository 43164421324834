import React, { memo, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useAppSelector } from '@common/hooks';
import { StoreState } from '@common/types/store';
import Icon from '@common/components/icon';
import './anonymity-banner.scss';

const AnonymityBanner = memo(() => {
  const [locale, articles] = useAppSelector((state: StoreState) => {
    return [
      state.loggedUser.user.language_locale,
      state.info.links.survey_anonymous_articles
    ];
  });
  const url = useMemo(() => {
    if (!articles) return;
    const article = articles.find((a) => a.locale === locale);
    if (!article) {
      const english = articles.find((a) => a.locale === 'en');
      return english?.url;
    }
    return article.url;
  }, [articles, locale]);

  return (
    <a
      className={`AnonymityBanner ${url ? '' : 'AnonymityBanner--no_cursor'}`}
      target="_blank"
      rel="noreferrer"
      href={url}
    >
      <Icon type="lock" />
      <Trans i18nKey="survey:anonymity_assurance" components={[<u />]} />
    </a>
  );
});

export default AnonymityBanner;
