import * as React from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import Item from './item';
import PureList from '../../../../common/components/list';
import AsyncList from '../../../../common/components/list/async';
import Placeholder from '../../../../common/components/placeholder';
import SectionHeader from '../../../../common/components/section-header';
import * as userSelector from '../../selectors/logged-user';

const isToday = (date) => {
  const today = new Date();

  return date.getDate() === today.getDate()
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear();
};

// Split notifications to today and older
const splitNotificationsByDate = (items, t) => {
  const [older, today] = items.reduce((acc, item) => {
    if (acc[0].length === 0 && isToday(new Date(item.created_at))) {
      if (!acc[1]) acc.push([]); // Create array for today

      acc[1].push(item); // Add to today

      return acc;
    }

    acc[0].push(item); // Add to older

    return acc;
  }, [[]]);

  const data = [];

  if (today && today.length > 0) {
    data.push({
      title: t('core:notifications_today'),
      items: today,
    });
  }

  if (older.length > 0) {
    data.push({
      title: t('core:notifications_older'),
      items: older,
    });
  }

  return data;
};

const DateSection = ({ item: { title, items }, onNotificationClick }) => (
  <React.Fragment key={title}>
    <SectionHeader size="small" title={title} />
    <PureList items={items} renderRow={Item} rowProps={{ onNotificationClick }} />
  </React.Fragment>
);

const NotificationPopover = ({ notifications, fetchNotifications, onNotificationClick, t }) => {
  const cache = {
    items: splitNotificationsByDate(notifications, t),
  };

  return (
    <div className="Notifications">
      <AsyncList
        items={cache.items}
        data={{
          cache,
          onFetch: fetchNotifications,
        }}
        renderRow={DateSection}
        rowProps={{
          onNotificationClick,
        }}
        placeholder={(
          <Placeholder
            description={t('core:notifications_placeholder')}
          />
        )}
        ShowMoreComponent={({ onShowMore }) => (
          <div className="Notifications__ShowMore" onClick={onShowMore}>
            <Trans i18nKey="common:async_list_load_more" />
          </div>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  notifications: userSelector.notifications(state),
});

const mapDispatchToProps = {
  fetchNotifications: require('../../actions/fetch-notifications').default,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NotificationPopover));
