import Api from '../../../common/services/api';

export const CLEAR_NOTIFICATIONS = 'core/CLEAR_NOTIFICATIONS';

export default () => (dispatch, getState) => {
  const { organisation: { selected } } = getState();
  Api.post(`/v2/organisations/${selected.id}/activities/seen-all`, { action: 'clear_unreads' });

  dispatch({ type: CLEAR_NOTIFICATIONS });
};
