import React, { memo, useCallback } from 'react';
import { Location } from 'history';
import { useRouteMatch } from 'react-router';
import PageLeaveCheckPopup from '@common/components/confirm-button/page-leave-check-popup';

type EditorPageLeavePopupProps = {
  message: string;
};

const EditorPageLeavePopup = memo(({ message }: EditorPageLeavePopupProps) => {
  const { url } = useRouteMatch();
  const shouldRunOnRoute = useCallback((newLocation: Location<unknown>) => {
    const baseUrl = url.substring(0, url.lastIndexOf('/'));
    // if the navigation is about going back and forth in the editor steps
    // let's ask if the user is sure about leaving the editor
    return !newLocation.pathname.startsWith(baseUrl);
  }, [url]);
  return (
    <PageLeaveCheckPopup message={message} shouldRunOnRoute={shouldRunOnRoute} />
  );
});

export default EditorPageLeavePopup;
