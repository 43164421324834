import React, { memo } from 'react';
import {
  APIEmployeeAcademyCourseModule,
  APIEmployeeAcademyCourseOverview
} from '@modules/learning/types/objects';
import PlayButton from '@common/components/play-button/play-button';
import { Icon } from '@common/components/icon';

type StatusIconProps = {
  entity: APIEmployeeAcademyCourseOverview | APIEmployeeAcademyCourseModule;
  progress?: number;
};

const StatusIcon = memo(({ entity, progress }: StatusIconProps) => {
  if (entity.availability?.locked) {
    return <Icon className="lock" type="lock" isFilled />;
  }
  if (entity.progress?.finished) {
    return <Icon className="check" type="check" />;
  }
  return <PlayButton progress={progress} progressThickness={2} />;
});

export default StatusIcon;
