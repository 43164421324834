import React, { PropsWithChildren, memo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@common/components/button';
import { FormSubmissionValues } from '@modules/forms/types';
import { useCurrentScreenErrors, useModuleNextScreenUrl } from '@modules/learning/hooks/employee-academy';
import { APIEmployeeAcademyCourseModule } from '@modules/learning/types/objects';

type OutcomePhaseNextButtonProps = PropsWithChildren<{
  className?: string;
  showIcon?: boolean;
  setOutcomePhase: (outcomePhase: boolean) => void;
  module: APIEmployeeAcademyCourseModule;
  values: FormSubmissionValues[];
}>;

const OutcomePhaseNextButton = memo(({
  children,
  showIcon = true,
  setOutcomePhase,
  values,
  module,
  className
}: OutcomePhaseNextButtonProps) => {

  const nextScreenUrl = useModuleNextScreenUrl(values.length);
  const errors = useCurrentScreenErrors(module, values);

  return (
    <Link
      className={`OutcomePhaseNextButton ${className || ''}`}
      onClick={() => setOutcomePhase(false)}
      to={nextScreenUrl!}
    >
      <Button
        type={errors.length > 0 ? 'warning' : 'success'}
        size="large"
        iconRight={showIcon ? 'arrow_right' : undefined}
      >
        { children }
      </Button>
    </Link>
  );
});

export default OutcomePhaseNextButton;
