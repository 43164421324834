import React, { memo, PropsWithChildren } from 'react';

type ScreenSectionProps = PropsWithChildren<{
  className?: string;
}>;

const ScreenSection = memo(({ children, className }: ScreenSectionProps) => {
  return (
    <div className={`Screen${className ? ` ${className}` : ''}`}>
      <div className="Screen__Inner">
        <div className="Screen__Content">
          <div className="List">
            { children }
          </div>
        </div>
      </div>
    </div>
  );
});

export default ScreenSection;
