import { useEffect, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@common/hooks';
import { getUserLocale } from '@modules/core/selectors/logged-user';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { useCurrentPage, useFieldValue, useNextPageUrl } from '@common/components/submission-editor/utils';
import { EComponentTypes } from '@modules/learning/definitions';
import { getCompletedCoursesProgress, getStatusData } from '../utils/employee-academy';
import {
  APIEmployeeAcademyCourseModule,
  APIEmployeeAcademyCourseOverview,
  APIEmployeeAcademyCourseResponse,
  APIEmployeeAcademyCoursesOverviewResponse,
  APILearningOverviewResponse
} from '../types/objects';
import { APIEmployeeLearningPathResponse } from '../types/learning-paths';
import {
  fetchEmployeeCourse,
  fetchEmployeeCourses,
  fetchEmployeeLearning,
  fetchEmployeeLearningPaths
} from '../utils/employee-academy-fetch';
import { FormSubmissionValues } from '@modules/forms/types';

const SUCCESS_PAGE = 1;

export const useStatusData = (dueDate?: string | null, completed?: boolean | null) => {
  const locale = useAppSelector(getUserLocale);
  return useMemo(() => getStatusData(locale, dueDate, completed), [dueDate, locale, completed]);
};

export const useCompletedCoursesProgress = (courses: APIEmployeeAcademyCourseOverview[]) => {
  return useMemo(() => getCompletedCoursesProgress(courses), [courses]);
};

export const useMergedCourses = (
  coursesRes: APIEmployeeAcademyCoursesOverviewResponse | null,
  learningPathsRes: APIEmployeeLearningPathResponse | null
) => {
  // we need to merge courses and learning paths courses and remove the
  // ones that are already in the courses
  return useMemo(() => {
    if (!learningPathsRes?.data || !coursesRes?.data) return null;

    const allCourses = [
      ...coursesRes.data,
      ...learningPathsRes.data.map((path) => path.courses).flat()
    ];
    return uniqBy(allCourses, 'id');
  }, [learningPathsRes, coursesRes]);
};

type Courses = APIEmployeeAcademyCoursesOverviewResponse | null;

export const useEmployeeCourses = (orgId: string) => {
  const [coursesRes, setCoursesRes] = useState<Courses>(null);

  useEffect(() => {
    fetchEmployeeCourses(orgId).then(setCoursesRes);
  }, [orgId, setCoursesRes]);

  return { coursesRes };
};

type LearningPaths = APIEmployeeLearningPathResponse | null;

export const useEmployeeLearningPaths = (orgId: string) => {
  const [learningPathsRes, setLearningPathsRes] = useState<LearningPaths>(null);

  useEffect(() => {
    fetchEmployeeLearningPaths(orgId).then(setLearningPathsRes);
  }, [orgId, setLearningPathsRes]);

  return { learningPathsRes };
};

type Learning = APILearningOverviewResponse | null;

export const useEmployeeLearning = (orgId: string) => {
  const [learningRes, setLearningRes] = useState<Learning>(null);

  useEffect(() => {
    fetchEmployeeLearning(orgId).then(setLearningRes);
  }, [orgId, setLearningRes]);

  return { learningRes };
};

export const useEmployeeCourse = (
  setCourse: (course: APIEmployeeAcademyCourseResponse | null) => void
) => {
  const { courseId } = useParams<{ courseId: string }>();
  const orgId = useSelector(getCurrentOrgId);

  useEffect(() => {
    fetchEmployeeCourse(orgId, courseId).then(setCourse);
    // let's reset the breadcrumbs on the parent component when we leave the component
    return () => setCourse(null);
  }, [courseId, setCourse, orgId]);
};

export const useCourseModule = (
  course: APIEmployeeAcademyCourseResponse | null,
  moduleId?: string
) => {
  return useMemo(() => {
    if (!moduleId) return;
    for (const section of course?.data.sections || []) {
      const _module = section.modules.find((mod) => mod.id === moduleId);
      if (_module) return _module;
    }
  }, [course, moduleId]);
};

export const useEmployeeAcademyBreadcrumbs = (
  course: APIEmployeeAcademyCourseResponse | null,
  networkId: string,
  moduleId?: string
) => {
  const { t } = useTranslation();

  const module = useCourseModule(course, moduleId);

  return useMemo(() => {
    const baseUrl = `/networks/${networkId}/academy`;

    let breadcrumbs = [{
      name: t('core:tab_elearning'),
      path: baseUrl
    }];
    if (!course?.data) return breadcrumbs;

    const coursePath = `${baseUrl}/course/${course.data.id}`;

    breadcrumbs = [
      ...breadcrumbs,
      { name: course.data.name, path: coursePath }
    ];

    if (module) {
      return [
        ...breadcrumbs,
        { name: module.name, path: `${coursePath}/module/${module.id}/id` }
      ];
    }
    return breadcrumbs;
  }, [module, course, t, networkId]);
};

export const useQuestionComponent = (module: APIEmployeeAcademyCourseModule | null | undefined) => {
  const currentPage = useCurrentPage();
  return useMemo(() => {
    if (!module) return null;
    const currentScreen = module?.screens?.[currentPage - 1];
    for (const component of currentScreen?.components || []) {
      if (component.type === EComponentTypes.ELEARNING_QUESTION) return component;
    }
    return null;
  }, [module, currentPage]);
};

export const useModuleCorrectAnswers = (module: APIEmployeeAcademyCourseModule | null | undefined) => {
  const questionComponent = useQuestionComponent(module);

  return useMemo(() => {
    if (!questionComponent) return [];
    return questionComponent.parameters.options.filter((option) => option.correct);
  }, [questionComponent]);
};

export const useCurrentScreenErrors = (
  module: APIEmployeeAcademyCourseModule | null | undefined,
  values: FormSubmissionValues[]
) => {
  const correctAnswers = useModuleCorrectAnswers(module);
  const questionComponent = useQuestionComponent(module);
  const value = useFieldValue(values, questionComponent?.id) as string[] | null;
  return useMemo(() => {
    const correctIds = correctAnswers.map((option) => option.id);
    return (value || []).filter((selectedId) => !correctIds.includes(selectedId));
  }, [value, correctAnswers]);
};

export const useModuleNextScreenUrl = (screensAmount: number) => {
  // we add one more page because the last page is the module performance page
  // not a page determined by the module screens list
  return useNextPageUrl(screensAmount + SUCCESS_PAGE);
};

