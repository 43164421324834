import React, { Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { APIEmployeeAcademyCourseOverview } from '@modules/learning/types/objects';
import { APIEmployeeLearningPath } from '@modules/learning/types/learning-paths';
import ElearningScrollSection from './elearning-scroll-section';
import {
  getCoursesCompleted,
  getCoursesToContinue,
  getCoursesToDiscover,
  getCoursesWithDeadline,
} from '../../../utils/employee-academy';

type ELearningCoursesProps = {
  courses: APIEmployeeAcademyCourseOverview[];
  learningPaths: APIEmployeeLearningPath[];
};

type Sections = {
  title: string;
  courses: APIEmployeeAcademyCourseOverview[];
  coursesModalSubPath: string;
}[];

const ELearningCourses = memo(({ courses, learningPaths }: ELearningCoursesProps) => {

  const { t } = useTranslation();

  const sections = useMemo(() => {

    const coursesToContinue = getCoursesToContinue(courses);
    const coursesWithDeadline = getCoursesWithDeadline(courses);
    const coursesToDiscover = getCoursesToDiscover(courses);
    const coursesCompleted = getCoursesCompleted(courses);

    const coursesLearningPaths = learningPaths.map((learningPath) => {
      return {
        title: learningPath.name,
        courses: learningPath.courses,
        coursesModalSubPath: `/overview/learning-path/${learningPath.id}`,
      };
    });

    return [
      coursesToContinue.length > 0 && {
        title: t('common:continue'),
        courses: coursesToContinue,
        coursesModalSubPath: '/overview/elearning/continue'
      },
      coursesWithDeadline.length > 0 && {
        title: t('learning:form_academy_deadline_label'),
        courses: coursesWithDeadline,
        coursesModalSubPath: '/overview/elearning/deadline'
      },

      ...coursesLearningPaths,

      coursesToDiscover.length > 0 && {
        title: t('common:discover'),
        courses: coursesToDiscover,
        coursesModalSubPath: '/overview/elearning/discover'
      },
      coursesCompleted.length > 0 && {
        title: t('learning:course_statistics_column_finished'),
        courses: coursesCompleted,
        coursesModalSubPath: '/overview/elearning/finished'
      },
    ].filter((section) => typeof section !== 'boolean') as Sections;

  }, [courses, learningPaths, t]);

  return (
    <>
      {
        sections.map((section, index, array) => {
          const lastSection = index >= (array.length - 1);
          return (
            <Fragment key={section.coursesModalSubPath}>
              <ElearningScrollSection {...section} />
              { !lastSection && <hr /> }
            </Fragment>
          );
        })
      }
    </>
  );

});

export default ELearningCourses;
