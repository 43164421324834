import React, { ReactNode, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  APIEmployeeAcademyCourseOverview,
  APILearningOverview,
} from '@modules/learning/types/objects';
import StatusLabel from './status-label';
import ProgressBadge from './progress-badge';
import './onboarding-badge.scss';

type OnboardingBadgeProps = {
  learning: APILearningOverview;
  courses: APIEmployeeAcademyCourseOverview[];
  showCount?: boolean;
  extraContent?: ReactNode;
};

const OnboardingBadge = memo(({
  learning,
  courses,
  showCount = true,
  extraContent
}: OnboardingBadgeProps) => {

  const { t } = useTranslation();
  const onboardingDueDate = learning?.onboarding?.due_date;

  const onboardingCourses = useMemo(() => {
    return courses.filter((course) => course.type === 'onboarding');
  }, [courses]);

  return (
    <div className="OnboardingBadge">
      <div className="OnboardingBadge__Title">
        <h3>
          { t('core:tab_onboarding') }
          { showCount && <span>{onboardingCourses?.length}</span> }
        </h3>
        {
          (onboardingDueDate || learning.onboarding?.finished) && (
            <StatusLabel
              dueDate={onboardingDueDate}
              completed={learning.onboarding?.finished}
            />
          )
        }
      </div>
      <ProgressBadge
        maxPoints={learning.onboarding?.max_points}
        currentPoints={learning.onboarding?.current_points}
        extraContent={extraContent}
      />
    </div>
  );
});

export default OnboardingBadge;


