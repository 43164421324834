import React, { memo, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  APIEmployeeAcademyCourseModule,
  APIEmployeeAcademyModuleAnswerResponse
} from '@modules/learning/types/objects';
import { FormSubmissionValues } from '@modules/forms/types';
import { useAppSelector } from '@common/hooks';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { Api } from '@common/services/api';
import Spinner from '@common/components/spinner';
import { combineClassNames } from '@common/utils/combineClassNames';
import { EModuleTypes } from '@modules/learning/definitions';
import { Button } from '@common/components/button';
import { replaceLastPathSegment, replaceLastPathSegments } from '@common/utils/url';
import { getInitialValues } from '@common/components/form/user-response-fields/utils';
import ModuleResultsCockade from './module-results-cockade';
import PerformanceBadge from './performance-badge';

import './module-results.scss';

type ModuleResultsProps = {
  module: APIEmployeeAcademyCourseModule;
  values: FormSubmissionValues[];
  setValues: (values: FormSubmissionValues[]) => void;
};

const ModuleResults = memo(({ module, values, setValues }: ModuleResultsProps) => {

  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentOrgId);
  const [result, setResult] = useState<APIEmployeeAcademyModuleAnswerResponse | null>(null);
  const { url } = useRouteMatch();

  useEffect(() => {
    const isContentModule = module.type === EModuleTypes.CONTENT;
    const answerUrl = `/v3/organisations/${orgId}/modules/${module.id}/answer`;

    let payload;
    if (isContentModule) {
      payload = {};
    } else {
      const questions = values.map((screen) => {
        for (const componentId in screen) {
          if (!Object.hasOwn(screen, componentId)) continue;
          const value = screen[componentId];
          // content components don't have value, question components do
          if (value) {
            return {
              id: componentId,
              option_ids: value
            };
          }
        }
        return null;
      }).filter(Boolean);

      payload = { questions };
    }

    Api.post<APIEmployeeAcademyModuleAnswerResponse>(answerUrl, payload).then(setResult);
  }, [module, values, orgId]);

  const moduleProgress = result?.data?.module_progress;

  const passed = !!moduleProgress?.finished;

  const className = combineClassNames('ModuleResults', {
    'ModuleResults--loading': !result
  });

  const achievedPoints = result?.data?.achieved_points || 0;
  const maxPoints = moduleProgress?.max_points || 1;

  const firstScreenUrl = replaceLastPathSegment(url, '1');
  const courseUrl = replaceLastPathSegments(url, 3, '');

  const onTryAgainClick = () => {
    setValues(getInitialValues(module.screens));
  };

  return (
    <div className={className}>
      {
        result ?
          (
            <div className="ModuleResults__Content">
              <ModuleResultsCockade
                success={passed}
                achievedPoints={achievedPoints}
                maxPoints={maxPoints}
              />
              <h2 className="ModuleResults__Title">
                {
                  passed ?
                    t('learning:module_results_title_success') :
                    t('learning:module_results_title_failure')
                }
              </h2>
              <p>
                {
                  passed ?
                    t('learning:module_results_subtitle_success') :
                    t('learning:module_results_subtitle_failure', {
                      count: result.data?.required_points || 0
                    })
                }
              </p>
              {
                module.type === EModuleTypes.QUIZ && (
                  <div className="ModuleResults__Badges">
                    <PerformanceBadge
                      className="PerformanceBadge--correct"
                      amount={result.data?.correct || 0}
                      label={t('common:correct')}
                      icon="check"
                    />
                    <PerformanceBadge
                      className="PerformanceBadge--incorrect"
                      amount={result.data?.incorrect || 0}
                      label={t('common:wrong')}
                      icon="close"
                    />
                    <PerformanceBadge
                      className="PerformanceBadge--attempts"
                      amount={result.data?.module_progress?.attempts || 0}
                      label={t('common:attempts')}
                      icon="refresh"
                    />
                  </div>
                )
              }
              {
                achievedPoints !== maxPoints && (
                  <Link
                    to={firstScreenUrl}
                    onClick={onTryAgainClick}
                    className="ModuleResults__ActionBtn"
                  >
                    <Button size="large" type={passed ? undefined : 'primary'}>
                      { t('learning:retry_quiz') }
                    </Button>
                  </Link>
                )
              }
              {
                passed && (
                  result.data?.unlocked_modules?.length > 0 ? (
                    <Link
                      to={replaceLastPathSegments(url, 2, `${result.data.unlocked_modules[0]}/1`)}
                      className="ModuleResults__ActionBtn"
                    >
                      <Button size="large" type="primary" iconRight="arrow_right">
                        { t('learning:continue_course') }
                      </Button>
                    </Link>
                  ) : (
                    <Link to={courseUrl} className="ModuleResults__ActionBtn">
                      <Button size="large">
                        { t('common:close') }
                      </Button>
                    </Link>
                  )
                )
              }
            </div>
          ) : (
            <Spinner size="large" />
          )
      }
    </div>
  );
});

export default ModuleResults;
