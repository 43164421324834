import React, { memo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APIEmployeeAcademyCourseOverview } from '@modules/learning/types/objects';
import BaseScrollSection from './base-scroll-section';
import './elearning-scroll-section.scss';

type ElearningScrollSectionProps = {
  courses: APIEmployeeAcademyCourseOverview[];
  title: string;
  coursesModalSubPath: string;
};

const ElearningScrollSection = memo(({
  courses,
  title,
  coursesModalSubPath
}: ElearningScrollSectionProps) => {

  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const coursesModalPath = `${url}${coursesModalSubPath}`;

  return (
    <BaseScrollSection
      className="ElearningScrollSection"
      header={(
        <div className="ElearningScrollSection__Header">
          <h3>{ title }</h3>
          <span>{ courses?.length }</span>
          <Link to={coursesModalPath}>{ t('common:view_all') }</Link>
        </div>
      )}
      courses={courses}
      coursesModalTitle={title}
      coursesModalPath={coursesModalPath}
    />
  );
});

export default ElearningScrollSection;
