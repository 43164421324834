import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { APIEmployeeAcademyCourseOverview, APIEmployeeAcademyCourseResponse, APILearningOverview }
  from '@modules/learning/types/objects';
import { Button } from '@common/components/button';
import PlayButton from '@common/components/play-button/play-button';
import { useCompletedCoursesProgress } from '@modules/learning/hooks/employee-academy';
import { useAppSelector } from '@common/hooks/redux';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { getFirstAvailableCourse, getFirstAvailableModuleUrl } from '@modules/learning/utils/employee-academy';
import { fetchEmployeeCourse } from '@modules/learning/utils/employee-academy-fetch';
import OnboardingBadge from './onboarding-badge';
import BaseScrollSection from './base-scroll-section';

import './onboarding-scroll-section.scss';

type OnboardingScrollSectionProps = {
  courses: APIEmployeeAcademyCourseOverview[];
  learning: APILearningOverview;
};

const OnboardingScrollSection = memo(({
  courses,
  learning
}: OnboardingScrollSectionProps) => {
  const { t } = useTranslation();
  const progress = useCompletedCoursesProgress(courses);

  const { url } = useRouteMatch();
  const coursesModalPath = `${url}/overview/onboarding`;

  const [course, setCourse] = useState<APIEmployeeAcademyCourseResponse | null>(null);

  const orgId = useAppSelector(getCurrentOrgId);
  useEffect(() => {
    const availableCourse = getFirstAvailableCourse(courses);
    if (!availableCourse) return;

    fetchEmployeeCourse(orgId, availableCourse.id).then(setCourse);
  }, [orgId, courses, setCourse]);

  const availableModuleUrl = useMemo(() => {
    if (!course) return null;
    return getFirstAvailableModuleUrl(course, `${url}/course/${course.data.id}`);
  }, [course, url]);

  return (
    <BaseScrollSection
      className="OnboardingScrollSection"
      header={(
        <div className="OnboardingScrollSection__Header">
          <OnboardingBadge
            courses={courses}
            learning={learning}
            extraContent={<Link to={coursesModalPath}>{ t('common:view_all') }</Link>}
          />
          <div className="OnboardingScrollSection__RightSide">
            {
              !learning?.onboarding?.finished && availableModuleUrl && (
                <Link to={availableModuleUrl} className="OnboardingScrollSection__ContinueButton">
                  <Button type="primary">
                    { t('learning:continue_onboarding') }
                  </Button>
                  <PlayButton progress={progress} progressThickness={3} />
                </Link>
              )
            }
          </div>
        </div>
      )}
      courses={courses}
      coursesModalTitle={t('core:tab_onboarding')}
      coursesModalPath={coursesModalPath}
    />
  );
});

export default OnboardingScrollSection;
