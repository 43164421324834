import React from 'react';
import { Icon, IconTypes } from '@common/components/icon';
import './performance-badge.scss';

type PerformanceBadgeProps = {
  amount: number;
  label: string;
  icon: IconTypes;
  className: string;
};

const PerformanceBadge = ({ className, amount, label, icon }: PerformanceBadgeProps) => {
  return (
    <div className={`PerformanceBadge ${className || ''}`}>
      <Icon type={icon} />
      <h2>{amount}</h2>
      <p>{label}</p>
    </div>
  );
};

export default PerformanceBadge;

