import Placeholder from '@common/components/placeholder';
import AsyncTable from '@common/components/table';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Api from '@common/services/api';
import FormCell from './form-cell';

const formsForMeUrl = (orgId: string, query: any) => {
  return `/v1/organisations/${orgId}/users/me/forms?${query}`;
};

type UserFormsTableProps = {
  orgId: string;
  baseUrl: string;
};

const UserFormsTable = memo(({ orgId, baseUrl }: UserFormsTableProps) => {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [{
      label: t('forms:overview_label_name'),
    }];
  }, [t]);

  const data = useMemo(() => {
    return {
      useCursor: true,
      onFetch: (
        nextCursor: string | null,
        filter: any,
        limit = 20
      ) => {
        const query = Api.utils.toQuery({
          limit,
          cursor: nextCursor || true
        });
        return Api.get(formsForMeUrl(orgId, query));
      },
      filter: {}
    };
  }, [orgId]);

  const renderRow = useCallback(({ item }: any) => {
    const href = `${baseUrl}/${item.id}/submission`;
    return [<FormCell form={item} href={href} />];
  }, [baseUrl]);

  return (
    // @ts-expect-error
    <AsyncTable
      columns={columns}
      data={data}
      renderRow={renderRow}
      placeholder={<Placeholder title={t('forms:no_forms_placeholder')} />}
    />
  );
});

export default UserFormsTable;
