import { useCallback } from 'react';
import { useHistory } from 'react-router';

// returns a function that allows navigating history without triggering the
// page-leave-check-popup (if it's present)
export const useHistoryActionWithoutConfirmation = (
  url: string,
  method: 'replace' | 'push' = 'replace'
) => {
  const history = useHistory();
  return useCallback(() => {
    history[method](url, { dontAskForPageLeaveConfirmation: true });
  }, [url, history, method]);
};
