import Api from '../../../common/services/api';

export const RECEIVE_NOTIFICATIONS = 'core/RECEIVE_NOTIFICATIONS';

export default (offset = 0, filter, limit = 30) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const query = Api.utils.toQuery({
    offset,
    limit,
  });

  const response = await Api.get(`/v3/organisations/${selected.id}/activities?${query}`);

  const { data, meta } = response;

  dispatch({
    type: RECEIVE_NOTIFICATIONS,
    items: data,
    meta,
    related: meta.related,
    strategy: meta.pagination.offset > 0 ? 'append' : null,
  });

  return response;
};
