import React, { memo, PropsWithChildren } from 'react';
import { Redirect, useRouteMatch } from 'react-router';
import { Container } from '@common/components/container';
import Spinner from '@common/components/spinner';
import { replaceLastPathSegment } from '@common/utils/url';
import EditorPageLeavePopup from './editor-page-leave-popup';

import './submission-editor.scss';


type RouteParams = {
  surveyId: string;
  networkId: string;
  page: string;
};

type SubmissionEditorProps = PropsWithChildren<{
  className?: string;
  loading: boolean;
  messageOnLeave?: string;
}>;

const SubmissionEditor = memo(({
  children,
  className,
  loading,
  messageOnLeave
}: SubmissionEditorProps) => {
  const { params: { page }, url } = useRouteMatch<RouteParams>();

  const currentPage = parseInt(page, 10);

  if (
    (currentPage !== currentPage) || // NaN check
    currentPage < 1 ||
    (loading && currentPage > 1)
  ) {
    const firstPage = replaceLastPathSegment(url, 1);
    return <Redirect to={firstPage} />;
  }

  return (
    <Container name="EditForm" className={`FormSubmissionEditor SubmissionEditor ${className}`}>
      {
        loading ?
          <Spinner size="large" centered /> :
          <>
            <style>{'body { background-color: inherit; }'}</style>
            { messageOnLeave && <EditorPageLeavePopup message={messageOnLeave} /> }
            { children }
          </>
      }
    </Container>
  );
});

export default SubmissionEditor;
