import React, { memo, useEffect, useState } from 'react';
import { Redirect, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { APIEmployeeAcademyCourseResponse } from '@modules/learning/types/objects';
import SubmissionEditor from '@common/components/submission-editor/submission-editor';
import {
  useCourseModule, useEmployeeAcademyBreadcrumbs, useEmployeeCourse
} from '@modules/learning/hooks/employee-academy';
import SubmissionEditorSteps from '@common/components/submission-editor/submission-editor-steps';
import { FormSubmissionValues } from '@modules/forms/types';
import { getInitialValues } from '@common/components/form/user-response-fields/utils';
import SubmissionEditorTopbar from '@common/components/submission-editor/submission-editor-topbar';
import { useCurrentPage } from '@common/components/submission-editor/utils';
import { EModuleTypes } from '@modules/learning/definitions';
import { combineClassNames } from '@common/utils/combineClassNames';
import { replaceLastPathSegments } from '@common/utils/url';
import NextScreenButton from './next-screen-button';
import { FeedbackModal } from './feedback-modal';
import ModuleResults from './module-results';

import './module-submission-editor.scss';

type ModuleSubmissionEditorProps = {
  course: APIEmployeeAcademyCourseResponse | null;
  setCourse: (course: APIEmployeeAcademyCourseResponse | null) => void;
  networkId: string;
};

type RouteParams = {
  moduleId: string;
  page: string;
  networkId: string;
};

const ModuleSubmissionEditor = memo(({
  course,
  setCourse,
  networkId
}: ModuleSubmissionEditorProps) => {

  useEmployeeCourse(setCourse);

  const { t } = useTranslation();
  const { params: { moduleId }, url } = useRouteMatch<RouteParams>();
  const breadcrumbs = useEmployeeAcademyBreadcrumbs(course, networkId, moduleId);

  const [values, setValues] = useState<FormSubmissionValues[] | null>(null);
  const [outcomePhase, setOutcomePhase] = useState<boolean>(false);

  const module = useCourseModule(course, moduleId);
  useEffect(() => {
    if (module) setValues(getInitialValues(module.screens));
  }, [module]);

  const courseUrl = replaceLastPathSegments(url, 3, '');
  const currentPage = useCurrentPage();
  const isCompletePage = currentPage > (values?.length || Infinity);
  const loaded = !!(values && module);
  const isQuizModule = module?.type === EModuleTypes.QUIZ;

  const nextBtn = loaded ?
    (
      <NextScreenButton
        values={values}
        module={module}
        setOutcomePhase={setOutcomePhase}
        outcomePhase={outcomePhase}
      />
    ) :
    null;

  const className = combineClassNames('ModuleSubmissionEditor', {
    'ModuleSubmissionEditor--outcomePhase': outcomePhase
  });

  // guard for error cases in which the API returns a module with no screens
  // we just go back to the course page
  if (module && ((module.screens?.length || 0) <= 0)) {
    return <Redirect to={courseUrl} />;
  }

  return (
    <SubmissionEditor className={className} loading={!loaded}>
      <SubmissionEditorTopbar
        title={module?.name}
        breadcrumbs={breadcrumbs}
        showProgress={!isCompletePage}
        allowPrevButton={!isCompletePage && !isQuizModule}
        stepsTotal={module?.screens.length || 0}
        editorCloseUrl={courseUrl}
        backButtonText={t('common:close')}
        nextBtn={isCompletePage ? null : nextBtn}
      />
      {
        loaded &&
        (
          isCompletePage ?
            <ModuleResults module={module} values={values} setValues={setValues} /> :
            (
              <>
                <SubmissionEditorSteps
                  screens={module?.screens || []}
                  values={values || []}
                  setValues={setValues}
                  showCorrectAnswers={!!module?.show_correct_answers}
                  outcomePhase={outcomePhase}
                />
                { nextBtn }
              </>
            )
        )
      }
      {
        isQuizModule && loaded && (
          <FeedbackModal
            outcomePhase={outcomePhase}
            module={module}
            values={values}
            setOutcomePhase={setOutcomePhase}
          />
        )
      }
    </SubmissionEditor>
  );
});

export default ModuleSubmissionEditor;
