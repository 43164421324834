export const transformUrl = (url) => {
  if (url.indexOf('http') === 0) return url;

  return `https://${url}`;
};

export const replaceLastPathSegments = (url, segmentsAmount, newSegment) => {
  const base = url.split('/').slice(0, -segmentsAmount).join('/');
  if (newSegment) {
    return `${base}/${newSegment}`;
  }
  return base;
};

export const replaceLastPathSegment = (url, newSegment) => {
  return replaceLastPathSegments(url, 1, newSegment);
};
