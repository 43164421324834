import React, { memo, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { APIEmployeeAcademyCourseModule } from '@modules/learning/types/objects';
import { EComponentQuestionTypes } from '@modules/learning/definitions';
import ModuleIcon from '../../module-icon';
import StatusIcon from '../home/status-icon';

import './module-row.scss';

type ModuleRowProps = {
  module: APIEmployeeAcademyCourseModule;
};

const questionTypes: string[] = Object.values(EComponentQuestionTypes);

const ModuleRow = memo(({ module }: ModuleRowProps) => {

  const screensCountI18n = module.type === 'quiz' ?
    'learning:module_child_count_quiz' :
    'learning:module_child_count_content';

  const count = useMemo(() => {
    if (module.type === 'quiz') {
      // count only the screens that contain questions
      return module.screens.filter((screen) => {
        return screen.components.find((component) => questionTypes.includes(component.type));
      }).length;
    }
    return module.screens.length;
  }, [module]);

  const { url } = useRouteMatch();

  const content = (
    <>
      <ModuleIcon module={module} />
      <div className="ModuleRow__Details">
        <h3>{module.name}</h3>
        <small>
          <Trans i18nKey={screensCountI18n} values={{ count }} />
          {' • '}
          <Trans
            i18nKey="learning:question_item_question_points"
            values={{ count: module.progress.max_points }}
          />
        </small>
      </div>
      <div className="ModuleRow__Status">
        <StatusIcon entity={module} />
      </div>
    </>
  );

  if (module.availability?.locked) {
    return <div className="ModuleRow">{content}</div>;
  }

  const moduleFirstPage = `${url}/module/${module.id}/1`;
  return (
    <Link className="ModuleRow" to={moduleFirstPage}>
      { content }
    </Link>
  );
});

export default ModuleRow;
