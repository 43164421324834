import React, { memo } from 'react';
import { APIScreen } from '@modules/forms/types';
import { APISurveyScreen } from '@modules/survey/types/objects';
import { Screen } from '@modules/learning/types/objects';
import ScreenSection from './screen-section';
import { getStaticComponent } from './utils';

type StaticScreenComponentsProps = {
  screen: APIScreen | APISurveyScreen | Screen;
};

const StaticScreenComponents = memo(({ screen }: StaticScreenComponentsProps) => {
  return (
    <ScreenSection key={screen.id}>
      {
        screen.components.map((component) => {
          const Component = getStaticComponent(component.type);
          if (Component) {
            return (
              <div className="ScreenComponent" key={component.id}>
                { /* @ts-expect-error */ }
                <Component item={component} />
              </div>
            );
          }
          return null;
        })
      }
    </ScreenSection>
  );
});

export default StaticScreenComponents;
