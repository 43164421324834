import React, { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from '@common/components/progress-bar/progress-bar';
import { Icon } from '@common/components/icon';
import './progress-badge.scss';

type ProgressBadgeProps = {
  maxPoints: number;
  currentPoints: number;
  extraContent?: ReactNode;
};

const ProgressBadge = memo(({ maxPoints, currentPoints, extraContent }: ProgressBadgeProps) => {

  const { t } = useTranslation();

  return (
    <div className="ProgressBadge">
      <div className="ProgressBadge__Content">
        <Icon
          type="paid"
          size="large"
          customClassName="ProgressBadge__Icon"
        />
        <h3>
          { currentPoints }{' '}
        </h3>
        <p className="ProgressBadge__Content__PointsLabel">
          / { maxPoints } { t('common:points_full') }
        </p>
        { extraContent }
      </div>
      <ProgressBar status={(currentPoints / maxPoints) * 100} />
    </div>
  );
});

export default ProgressBadge;
