import React, { ReactNode, memo, useCallback } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router';
import { APIEmployeeAcademyCourseOverview } from '@modules/learning/types/objects';
import HorizontalScrollArea from '@common/components/horizontal-scroll-area/horizontal-scroll-area';
import CourseCard from './course-card';
import CoursesOverviewModal from './courses-overview-modal';
import './base-scroll-section.scss';

type BaseScrollSectionProps = {
  courses: APIEmployeeAcademyCourseOverview[];
  header: ReactNode;
  coursesModalPath: string;
  coursesModalTitle: string;
  className: string;
};

const BaseScrollSection = memo(({
  courses,
  header,
  className,
  coursesModalPath,
  coursesModalTitle
}: BaseScrollSectionProps) => {

  const { url } = useRouteMatch();
  const history = useHistory();
  const onModalHide = useCallback(() => {
    history.push(url);
  }, [history, url]);

  return (
    <div className={`BaseScrollSection ${className}`}>
      { header }
      <HorizontalScrollArea>
        {
          courses.map((course) => (
            <CourseCard
              className="HorizontalScrollArea__Card"
              key={course.id}
              course={course}
            />
          ))
        }
      </HorizontalScrollArea>
      <Route path={coursesModalPath}>
        <CoursesOverviewModal
          onHide={onModalHide}
          title={coursesModalTitle}
          courses={courses}
        />
      </Route>
    </div>
  );
});

export default BaseScrollSection;
