import React, {
  ReactNode, memo, useCallback, useEffect, useState
} from 'react';
import { Action, Location } from 'history';
import { useHistory } from 'react-router';
import ModalContent from '@common/components/modal/modal-content';
import ModalWindow from '@common/components/modal/modal-window';
import { Actions } from '@common/utils/history';

const { POP, REPLACE, PUSH } = Actions;

type PageLeaveCheckPopupProps = {
  title?: string;
  message: ReactNode;
  shouldRunOnRoute: (location: Location<unknown>, action: Action) => boolean;
};

type RouteChangeConfig = {
  action: Action;
  location: Location<unknown>;
};

const PageLeaveCheckPopup = memo(({
  message,
  title,
  shouldRunOnRoute
}: PageLeaveCheckPopupProps) => {

  const [routeUpdateConfig, setRouteUpdateConfig] = useState<null | RouteChangeConfig>(null);
  const modalOpen = !!routeUpdateConfig;
  const history = useHistory();

  useEffect(() => {
    const unblock = history.block((location, action) => {
      // POP is back button action, it's impossible to override that action as
      // it immediately updates the url the URL, see
      // https://linear.app/oneteam/issue/ONE-493/as-a-user-i-want-close-the-survey-that-im-taking#comment-e65b117d
      if (
        action !== POP &&
        !modalOpen &&
        shouldRunOnRoute(location, action) &&
        !(location.state as any)?.dontAskForPageLeaveConfirmation
      ) {
        setRouteUpdateConfig({ location, action });
        return false;
      }
      setRouteUpdateConfig(null); // close the modal
    });

    return () => {
      unblock();
    };
  }, [history, setRouteUpdateConfig, modalOpen]);

  const onHide = useCallback(() => setRouteUpdateConfig(null), [setRouteUpdateConfig]);

  const onConfirm = useCallback(() => {
    if (!routeUpdateConfig) return;

    const { action, location } = routeUpdateConfig;
    const path = `${location.pathname}${location.search}`;

    if (action === PUSH) return history.push(path);
    if (action === REPLACE) return history.replace(path);
  }, [routeUpdateConfig, history]);

  return (
    <ModalWindow
      show={!!modalOpen}
      onHide={onHide}
      className="ConfirmButton PageLeaveCheckPopup"
    >
      <ModalContent
        hideHeader
        onHide={onHide}
        onConfirm={onConfirm}
      >
        {
          title && (
            <h3>{ title }</h3>
          )
        }
        <div className="ConfirmButton__description">
          { message }
        </div>
      </ModalContent>
    </ModalWindow>
  );
});

export default PageLeaveCheckPopup;
