import React, { memo, useMemo } from 'react';
import ProportionChart from './proportion-chart';

type ProgressChartProps = {
  stepsTotal: number;
  currentStep: number;
};

const BLOCK_SIZE = 1;
const LIGHT_GREY = '#E5E7EB';

const ProgressChart = memo(({ stepsTotal, currentStep }: ProgressChartProps) => {
  const bars = useMemo(() => {
    return Array(stepsTotal).fill(null).map((_, index) => {
      return {
        // for our cases it's fine, should stepsTotal be dynamic this will result in bugs
        // with the coloring
        key: index,
        value: BLOCK_SIZE,
        color: index >= currentStep ? LIGHT_GREY : 'var(--color-button)'
      };
    });
  }, [stepsTotal, currentStep]);
  return (
    <ProportionChart className="ProgressChart" bars={bars} />
  );
});

export default ProgressChart;
