import Placeholder from '@common/components/placeholder';
import AsyncTable from '@common/components/table';
import { SubmissionStatus } from '@modules/forms/components/submission-status';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Api from '@common/services/api';
import FormCell from './form-cell';
import moment from 'moment';


export const mySubmittedFormsUrl = (orgId: string, query: string) => {
  return `/v1/organisations/${orgId}/users/me/forms/submissions?${query}`;
};

type UserSubmissionsTableProps = {
  orgId: string;
  baseUrl: string;
};

const UserSubmissionsTable = memo(({
  orgId,
  baseUrl
}: UserSubmissionsTableProps) => {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [{
      label: t('forms:overview_label_name'),
    }, {
      label: t('forms:submission_header_submitted_at')
    }, {
      label: t('forms:submission_header_status')
    }, {
      label: t('core:comments')
    }];
  }, [t]);

  const data = useMemo(() => {
    return {
      useCursor: true,
      onFetch: (
        nextCursor: string | null,
        filter: any,
        limit = 20
      ) => {
        const query = Api.utils.toQuery({
          limit,
          cursor: nextCursor || true,
          submission_status: 'published'
        });
        return Api.get(mySubmittedFormsUrl(orgId, query));
      },
      filter: {}
    };
  }, [orgId]);

  const renderRow = useCallback(({ item }: any) => {
    const href = `${baseUrl}/${item.form.id}/submission/${item.id}`;
    return [
      <span className="formTitle">
        <FormCell form={item.form} href={href} />
        <span>#{ item.index }</span>
      </span>,
      item.published_at ?
        moment(item.published_at).format('MMM D, YYYY') :
        <small>-</small>,
      item.form?.settings?.show_status_to_users ?
        <SubmissionStatus status={item.response_status} /> :
        <small>-</small>,
      item.comments_count
    ];
  }, [baseUrl]);

  return (
    // @ts-expect-error
    <AsyncTable
      columns={columns}
      data={data}
      containerClassName="UserSubmissionsTable"
      renderRow={renderRow}
      placeholder={<Placeholder title={t('forms:no_forms_placeholder')} />}
    />
  );
});

export default UserSubmissionsTable;
