import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { APIEmployeeAcademyCourseOverview } from '@modules/learning/types/objects';
import { Icon } from '@common/components/icon';
import TextHighlight from '@common/components/text-highlight';
import { getSelectedNetwork } from '@modules/network/selectors/network';
import { useAppSelector } from '@common/hooks';
import { DEFAULT_COURSE_IMAGE } from '@modules/learning/utils/employee-academy';
import { useStatusData } from '@modules/learning/hooks/employee-academy';
import StatusIcon from './status-icon';
import './course-card.scss';

type CourseCardProps = {
  course: APIEmployeeAcademyCourseOverview;
  className?: string;
  href?: string;
  search?: string;
};

const CourseCard = ({ course, className, href, search }: CourseCardProps) => {
  const { t } = useTranslation();

  const {
    current_points,
    max_points,
    finished_module_count,
    module_count
  } = course.progress || {};

  const { label, className: expirationClassName } = useStatusData(course.progress.deadline);
  const progress = finished_module_count / module_count;

  const backgroundImage = `url(${course.header_image_url}), url(${DEFAULT_COURSE_IMAGE})`;

  const imageAndTitle = (
    <>
      <div
        className="CourseCard__Header"
        style={{
          backgroundImage,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <StatusIcon entity={course} progress={progress} />
        {
          course.progress.deadline && !course.progress.finished && (
            <div className="CourseCard__ExpirationBadge">
              {
                label && (
                  <p>
                    {
                      typeof label === 'string' ?
                        label :
                        <Trans i18nKey={label.i18nKey} />
                    }
                  </p>
                )
              }
              <Icon className={expirationClassName} size={14} type="pending_actions" />
            </div>
          )
        }
      </div>
      <h3 className="CourseCard__CourseName">
        <TextHighlight text={course.name} highlightText={search} />
      </h3>
    </>
  );

  const network = useAppSelector(getSelectedNetwork);
  const linkPath = `/networks/${network.id}/academy/course/${course.id}`;

  return (
    <div className={`CourseCard ${className || ''}`}>
      {
        course.availability?.locked ?
          imageAndTitle :
          <Link to={href || linkPath}>{ imageAndTitle }</Link>
      }
      <small className="CourseCard__Progress">
        { current_points || 0 }/{ max_points || 0 } { t('common:points_full') }
        {
          typeof course.duration === 'number' && (
            <>
              <span className="CourseCard__Progress__Separator">{' • '}</span>
              {`${course.duration} ${t('common:minutes')}`}
            </>
          )
        }
      </small>
    </div>
  );
};

export default CourseCard;
