import React, { memo, useMemo } from 'react';
import { Accordion } from '@szhsin/react-accordion';
import SimpleAccordionItem from '@common/components/accordion/simple-accordion-item';
import { Icon } from '@common/components/icon';
import { APIEmployeeAcademyCourseSection } from '@modules/learning/types/objects';
import ModuleRow from './module-row';

import './course-section.scss';

type CourseSectionProps = {
  section: APIEmployeeAcademyCourseSection;
};

const CourseSection = memo(({ section }: CourseSectionProps) => {

  const content = useMemo(() => {
    return section.modules.map((module) => {
      return <ModuleRow key={module.id} module={module} />;
    });
  }, [section]);

  const finishedModulesCount = useMemo(() => {
    return section.modules.reduce((acc, module) => {
      if (module.progress.finished) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }, [section]);

  if (section.transparent) {
    return <>{ content }</>;
  }

  const sectionFinished = finishedModulesCount >= section.modules.length;

  return (
    <Accordion className="CourseSection">
      <SimpleAccordionItem
        title={section.name || ''}
        initialEntered={!sectionFinished}
        rightSide={(
          sectionFinished ?
            <Icon className="CourseSection__Check" type="check" /> :
            <small>
              {finishedModulesCount}/{section.modules.length}
            </small>
        )}
      >
        { content }
      </SimpleAccordionItem>
    </Accordion>
  );
});

export default CourseSection;
