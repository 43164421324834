import { useMemo } from 'react';
import { useLocation, useParams, useRouteMatch } from 'react-router';
import { replaceLastPathSegment } from '@common/utils/url';
import { FormSubmissionValues } from '@modules/forms/types';

export const useCurrentPage = () => {
  const { page } = useParams<{ page: string }>();
  return parseInt(page, 10);
};

export const usePreviousPageUrl = () => {
  const { search } = useLocation();
  const { url } = useRouteMatch();
  const currentPage = useCurrentPage();
  return useMemo(() => {
    if (currentPage > 1) {
      return `${replaceLastPathSegment(url, currentPage - 1)}${search}`;
    }
    return null;
  }, [currentPage, url, search]);
};

export const useNextPageUrl = (lastPage: number) => {
  const { search } = useLocation();
  const { url } = useRouteMatch();
  const currentPage = useCurrentPage();
  return useMemo(() => {
    if (currentPage < lastPage) {
      return `${replaceLastPathSegment(url, currentPage + 1)}${search}`;
    }
    return null;
  }, [lastPage, currentPage, url, search]);
};

export const useFieldValue = (
  values: FormSubmissionValues[],
  questionId?: string
) => {
  const currentPage = useCurrentPage();
  if (!questionId) return null;
  return values[currentPage - 1][questionId];
};
