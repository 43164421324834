import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  APIEmployeeAcademyCourseOverview,
  APILearningOverview
} from '@modules/learning/types/objects';
import Placeholder from '@common/components/placeholder/placeholder';
import { normalizeText } from '@common/components/search-bar/utils';
import { isEmployeeOnboardingEnabled } from '@modules/core/selectors/logged-user';
import { useAppSelector } from '@common/hooks';
import { isElearningEnabled } from '@modules/organisation/selectors/organisation';
import { APIEmployeeLearningPath } from '@modules/learning/types/learning-paths';
import { ECourseTypes } from '@modules/learning/definitions';
import ELearningCourses from './elearning-courses';
import CoursesGrid from './courses-grid';
import OnboardingScrollSection from './onboarding-scroll-section';
import './academy-courses.scss';

type AcademyCoursesProps = {
  courses: APIEmployeeAcademyCourseOverview[];
  learning: APILearningOverview;
  learningPaths: APIEmployeeLearningPath[];
  search: string;
};

const AcademyCourses = memo(({
  courses,
  learning,
  learningPaths,
  search
}: AcademyCoursesProps) => {

  const { t } = useTranslation();
  const onboardingEnabled = useAppSelector(isEmployeeOnboardingEnabled);
  const elearningEnabled = useAppSelector(isElearningEnabled);

  const filteredCourses = useMemo(() => {
    const normalizedSearch = normalizeText(search);
    return courses.filter((course) => normalizeText(course.name).includes(normalizedSearch));
  }, [courses, search]);

  const [onboardingCourses, elearningCourses] = useMemo(() => {
    return [
      courses.filter((course) => course.type === ECourseTypes.ONBOARDING),
      courses.filter((course) => course.type === ECourseTypes.ACADEMY)
    ];
  }, [courses]);

  if (search) {
    if (filteredCourses.length > 0) {
      return (
        <>
          <hr />
          <CoursesGrid
            search={search}
            courses={filteredCourses}
            title={t('common:search_results')}
          />
        </>
      );
    }
  } else {

    const showOnboarding = onboardingEnabled && onboardingCourses.length > 0;
    const showElearning = elearningEnabled && elearningCourses.length > 0;

    if (showOnboarding && showElearning) {
      return (
        <>
          <OnboardingScrollSection courses={onboardingCourses} learning={learning} />
          <hr className="AcademyCourses__firstSeparator" />
          <ELearningCourses courses={elearningCourses} learningPaths={learningPaths} />
        </>
      );
    }

    if (showElearning) {
      return <ELearningCourses courses={elearningCourses} learningPaths={learningPaths} />;
    }

    if (showOnboarding) {
      return (
        <CoursesGrid
          courses={onboardingCourses}
          title={t('learning:all_courses')}
        />
      );
    }
  }

  return (
    <Placeholder
      title={t('learning:employee_academy_no_courses_title')}
      description={
        search ?
          t('learning:employee_academy_no_courses_description_search') :
          t('learning:employee_academy_no_courses_description')
      }
      size="small"
      image="/static/images/placeholders/no-results.svg"
    />
  );
});

export default AcademyCourses;
