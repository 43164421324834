import React, { memo } from 'react';
import { Trans } from 'react-i18next';
import { Icon } from '@common/components/icon';
import { useStatusData } from '@modules/learning/hooks/employee-academy';
import './status-label.scss';

type StatusLabelProps = {
  dueDate?: string | null;
  completed: boolean | null;
};

const StatusLabel = memo(({ dueDate, completed }: StatusLabelProps) => {
  const { label, className } = useStatusData(dueDate, completed);
  return (
    <div className={`StatusLabel ${className ? `${className}` : ''}`}>
      <Icon size={18} type="pending_actions" />
      {
        label && (
          <p>
            {
              typeof label === 'string' ?
                label :
                <Trans i18nKey={label.i18nKey} />
            }
          </p>
        )
      }
    </div>
  );
});

export default StatusLabel;
