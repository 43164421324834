import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { SubNavigationBar, TopNavigationBar }
  from '@common/components/navigation-bar';
import { Container } from '@common/components/container';
import Overview from '@common/components/overview';
import * as organisationSelector
  from '@modules/organisation/selectors/organisation';
import { Api } from '@common/services/api';
import { EFilters } from '../../constants';
import ModeratorForm from './moderator-form';
import FormsManagedByMeTable, { generateManagedByMeUrl }
  from './forms-managed-by-me-table';
import UserSubmissionsTable from './user-submissions-table';
import UserFormsTable from './user-forms-table';
import UserDraftsTable from './user-drafts-table';
import { SubmissionCreationEditor, SubmissionDraftEditor, SubmissionViewer }
  from './form-submission-editor';
import './network-forms.scss';

type Params = {
  networkId?: string,
  filter?: EFilters;
  q?: string;
};

type RouteProps = RouteComponentProps<Params>;

type Props = RouteProps;

export default function NetworkForms({ match }: any) {
  // console.log('debug NetworkForms match', match);
  const { path } = match;
  const moderatorSubmissionsRoutes = useMemo(() => {
    return [
      `${path}/:id/edit`,
      `${path}/:id/edit/:screenId`,
      `${path}/:id/submissions`,
      `${path}/:id/submissions/:submissionId`,
    ];
  }, [path]);
  return (
    <Switch>
      <Route
        path={moderatorSubmissionsRoutes}
        component={ModeratorForm}
        exact
      />
      <Route
        path={`${path}/:formId/submission`}
        component={SubmissionCreationEditor}
        exact
      />
      <Route
        path={`${path}/:formId/draft/:submissionId`}
        component={SubmissionDraftEditor}
        exact
      />
      <Route
        path={`${path}/:formId/submission/:submissionId`}
        component={SubmissionViewer}
      />
      <Route component={NetworkFormsTables} />
    </Switch>
  );
}

type Counts = {
  managedByMe: number | undefined;
  forms: number | undefined,
  submissionDrafts: number | undefined,
  submissions: number | undefined
};

export const mySubmittedFormsUrl = (orgId: string, query: string) => {
  return `/v1/organisations/${orgId}/users/me/forms/submissions?${query}`;
};

const getTotal = (result: any) => {
  return result?.meta?.counts?.total || undefined;
};

function useItemsCounts(orgId: string) {
  const [counts, setCounts] = useState<Counts>({
    managedByMe: undefined,
    forms: undefined,
    submissionDrafts: undefined,
    submissions: undefined
  });

  useEffect(() => {
    const query = 'limit=0';
    Promise.all([
      Api.get(generateManagedByMeUrl(orgId, query)).catch((error) => {
        console.log('error', error);
        return { meta: { counts: { total: 0 } } };
      }),
      Api.get(`/v1/organisations/${orgId}/users/me/forms`),
      Api.get(mySubmittedFormsUrl(orgId, `${query}&submission_status=draft`)),
      Api.get(mySubmittedFormsUrl(orgId, `${query}&submission_status=published`))
    ]).then((results: any) => {
      setCounts({
        managedByMe: getTotal(results[0]),
        forms: getTotal(results[1]),
        submissionDrafts: getTotal(results[2]),
        submissions: getTotal(results[3])
      });
    });
  }, [setCounts, orgId]);

  return counts;
}

function NetworkFormsTables(props: Props) {
  // console.log('debug NetworkForms props', props);
  const orgId = useSelector(organisationSelector.selected).id;
  const { match } = props;

  const { t } = useTranslation();

  const counts = useItemsCounts(orgId);

  const canViewManagedByMe = (
    typeof counts.managedByMe === 'number' && counts.managedByMe > 0
  );

  return (
    <>
      <SubNavigationBar title={t('core:tab_forms')}>
        <SubNavigationBar.Item
          exact
          path={`${match.url}/me/forms`}
          title={t('forms:user_forms')}
          count={counts.forms}
        />
        <SubNavigationBar.Item
          exact
          path={`${match.url}/me/drafts`}
          title={t('forms:user_drafts')}
          count={counts.submissionDrafts}
        />
        <SubNavigationBar.Item
          exact
          path={`${match.url}/me/user-submissions`}
          title={t('forms:user_submissions')}
          count={counts.submissions}
        />
        {
          canViewManagedByMe && (
            <SubNavigationBar.Item
              path={`${match.url}/managed-by-me`}
              title={t('forms:managed_by_me')}
              count={counts.managedByMe}
            />
          )
        }
      </SubNavigationBar>
      <Container name="Forms">
        <TopNavigationBar
          title={
            <>
              <Route path={`${match.url}/me/forms`} exact>
                { t('forms:user_forms') }
              </Route>
              <Route path={`${match.url}/me/drafts`} exact>
                { t('forms:user_drafts') }
              </Route>
              <Route path={`${match.url}/me/user-submissions`} exact>
                { t('forms:user_submissions') }
              </Route>
              {
                canViewManagedByMe && (
                  <Route path={`${match.url}/managed-by-me`}>
                    { t('forms:managed_by_me') }
                  </Route>
                )
              }
            </>
          }
        />
        <Container.Content>
          <Overview>
            <Route path={`${match.url}/me/forms`}>
              <UserFormsTable baseUrl={match.url} orgId={orgId} />
            </Route>
            <Route path={`${match.url}/me/drafts`}>
              <UserDraftsTable baseUrl={match.url} orgId={orgId} />
            </Route>
            <Route path={`${match.url}/me/user-submissions`}>
              <UserSubmissionsTable baseUrl={match.url} orgId={orgId} />
            </Route>
            {
              canViewManagedByMe && (
                <Route path={`${match.url}/managed-by-me`}>
                  <FormsManagedByMeTable baseUrl={match.url} orgId={orgId} />
                </Route>
              )
            }
          </Overview>
        </Container.Content>
      </Container>
    </>
  );
}

