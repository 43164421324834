import React, { memo } from 'react';
import ModalContent from '@common/components/modal/modal-content';
import ModalWindow from '@common/components/modal/modal-window';
import { APIEmployeeAcademyCourseOverview } from '@modules/learning/types/objects';
import CourseCard from './course-card';
import './courses-overview-modal.scss';

type CoursesOverviewModalProps = {
  title: string;
  courses: APIEmployeeAcademyCourseOverview[];
  onHide: () => void;
};

const MAX_COURSES_PER_ROW = 6;

const CoursesOverviewModal = memo(({ title, courses, onHide }: CoursesOverviewModalProps) => {
  const sizeLarge = courses.length >= MAX_COURSES_PER_ROW;
  return (
    <ModalWindow
      size={sizeLarge ? 'large' : undefined}
      show
      className={`CoursesOverviewModal ${sizeLarge ? 'CoursesOverviewModal--large' : ''}`}
      onHide={onHide}
    >
      <ModalContent title={title} hideFooter onHide={onHide}>
        {
          courses.map((course) => (
            <CourseCard key={course.id} course={course} />
          ))
        }
      </ModalContent>
    </ModalWindow>
  );
});

export default CoursesOverviewModal;
