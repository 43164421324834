import { Api } from '@common/services/api';
import { APIEmployeeLearningPathResponse } from '@modules/learning/types/learning-paths';
import {
  APIEmployeeAcademyCourseResponse,
  APIEmployeeAcademyCoursesOverviewResponse,
  APILearningOverviewResponse
} from '@modules/learning/types/objects';

export const fetchEmployeeCourses = (orgId: string, type?: 'onboarding' | 'academy') => {
  let url = `/v2/organisations/${orgId}/users/me/courses`;
  if (type === 'onboarding') {
    url += `?type=${type}`;
  }
  return Api.get<APIEmployeeAcademyCoursesOverviewResponse>(url);
};

export const fetchEmployeeLearningPaths = (orgId: string) => {
  const url = `/v2/organisations/${orgId}/users/me/learning-paths`;
  return Api.get<APIEmployeeLearningPathResponse>(url);
};

export const fetchEmployeeLearning = (orgId: string) => {
  const url = `/v2/organisations/${orgId}/users/me/learning`;
  return Api.get<APILearningOverviewResponse>(url);
};

export const fetchEmployeeCourse = (orgId: string, courseId: string) => {
  const url = `/v2/organisations/${orgId}/users/me/courses/${courseId}`;
  return Api.get<APIEmployeeAcademyCourseResponse>(url);
};
