import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContent from '@common/components/modal/modal-content';
import ModalWindow from '@common/components/modal/modal-window';
import { useCurrentScreenErrors, useQuestionComponent } from '@modules/learning/hooks/employee-academy';
import { APIEmployeeAcademyCourseModule, Screen } from '@modules/learning/types/objects';
import { useCurrentPage } from '@common/components/submission-editor/utils';
import { FormSubmissionValues } from '@modules/forms/types';
import { combineClassNames } from '@common/utils/combineClassNames';
import StaticScreenComponents
  from '@common/components/form/user-response-fields/static-screen-components';
import OutcomePhaseNextButton from './outcome-phase-next-button';

import './feedback-modal.scss';

type FeedbackModalProps = {
  outcomePhase: boolean;
  module: APIEmployeeAcademyCourseModule;
  values: FormSubmissionValues[];
  setOutcomePhase: (outcomePhase: boolean) => void;
};

export const FeedbackModal = memo(({
  outcomePhase,
  module,
  values,
  setOutcomePhase
}: FeedbackModalProps) => {
  const { t } = useTranslation();

  const errors = useCurrentScreenErrors(module, values);
  const gotErrors = errors.length > 0;

  const questionComponent = useQuestionComponent(module);
  const correctFeedback = questionComponent?.parameters?.correct_feedback || [];
  const incorrectFeedback = questionComponent?.parameters?.incorrect_feedback || [];

  const show = outcomePhase && (
    (correctFeedback.length > 0 && !gotErrors) || (incorrectFeedback.length > 0 && gotErrors)
  );

  const className = combineClassNames('FeedbackModal', {
    'FeedbackModal--correct': !gotErrors,
    'FeedbackModal--incorrect': gotErrors
  });

  const title = gotErrors ? t('learning:incorrect_answer') : t('learning:correct_answer');

  const fakeScreen = useMemo(() => {
    return {
      components: gotErrors ? incorrectFeedback : correctFeedback
    };
  }, [correctFeedback, incorrectFeedback, gotErrors]) as Screen;

  const currentPage = useCurrentPage();
  const isLastScreen = currentPage >= values.length;

  const customFooter = (
    <div className="pull-right">
      <OutcomePhaseNextButton
        module={module}
        values={values}
        setOutcomePhase={setOutcomePhase}
        showIcon={false}
      >
        {
          isLastScreen ? t('common:complete') : t('common:continue')
        }
      </OutcomePhaseNextButton>
    </div>
  );

  return (
    <ModalWindow show={show} className={className}>
      <ModalContent title={title} customFooter={customFooter}>
        <StaticScreenComponents screen={fakeScreen} />
      </ModalContent>
    </ModalWindow>
  );
});
