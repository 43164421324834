import React, { memo } from 'react';
import { AccordionItem, AccordionItemProps } from '@szhsin/react-accordion';
import { Icon } from '../icon';
import './simple-accordion-item.scss';

type SimpleAccordionItemProps = AccordionItemProps & {
  title: string;
  className?: string;
  rightSide?: React.ReactNode;
};

const SimpleAccordionItem = memo(({
  title, className, rightSide, ...otherProps
}: SimpleAccordionItemProps) => {
  return (
    <AccordionItem
      {...otherProps}
      className={`SimpleAccordionItem ${className || ''}`}
      header={(
        <>
          <h3>{title}</h3>
          <div className="SimpleAccordionItem__RightSide">
            { rightSide }
            <Icon type="expand_more" className="SimpleAccordionItem__Chevron" />
          </div>
        </>
      )}
    />
  );
});

export default SimpleAccordionItem;
