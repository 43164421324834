import React, { ReactNode, memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, TopNavigationBar } from '@common/components/navigation-bar';
import { Button } from '@common/components/button';
import ProgressChart from '@common/components/proportion-chart/progress-chart';
import Spinner from '@common/components/spinner';
import { useCurrentPage, usePreviousPageUrl } from './utils';

import './submission-editor-topbar.scss';

type SubmissionEditorTopbarProps = {
  title?: string;
  breadcrumbs: Breadcrumb[];
  stepsTotal: number;
  showProgress?: boolean;
  allowPrevButton?: boolean;
  editorCloseUrl: string;
  backButtonText?: string | null;
  nextBtn: ReactNode;
};

const SubmissionEditorTopbar = memo(({
  title,
  breadcrumbs,
  stepsTotal,
  editorCloseUrl,
  showProgress = true,
  allowPrevButton = true,
  backButtonText,
  nextBtn
}: SubmissionEditorTopbarProps) => {

  const { t } = useTranslation();

  const currentPage = useCurrentPage();

  const prevPageUrl = usePreviousPageUrl();

  return (
    <TopNavigationBar
      className="SubmissionEditorTopbar"
      breadcrumbs={breadcrumbs}
      title={
        title ||
        <span>
          <Spinner size="large" />
        </span>
      }
      action={(
        <>
          {
            typeof stepsTotal === 'number' && showProgress && (
              <>
                <span className="SubmissionEditorTopbar__StepsCount">
                  { currentPage }/{ stepsTotal }
                </span>
                <ProgressChart
                  stepsTotal={stepsTotal}
                  currentStep={currentPage - 1}
                />
              </>
            )
          }
          <div className="SubmissionEditorTopbar__ResponseActions">
            <Link to={editorCloseUrl}>
              <Button size="large">{ backButtonText }</Button>
            </Link>
            {
              prevPageUrl && allowPrevButton && (
                <Link to={prevPageUrl}>
                  <Button
                    size="large"
                    icon="chevron_left"
                    className="SubmissionEditorTopbar__PreviousBtn"
                    type="primary"
                  >
                    { t('common:previous') }
                  </Button>
                </Link>
              )
            }
            { nextBtn }
          </div>
        </>
      )}
    />
  );
});

export default SubmissionEditorTopbar;
