import React, { Dispatch, SetStateAction, memo } from 'react';
import { Route, useRouteMatch } from 'react-router';
import { APISurveyScreen } from '@modules/survey/types/objects';
import { FormSubmissionValues } from '@modules/forms/types';
import { replaceLastPathSegment } from '@common/utils/url';
import { Screen } from '@modules/learning/types/objects';
import SubmissionEditorStep from './submission-editor-step';

type SubmissionEditorStepsProps = {
  className?: string;
  screens?: APISurveyScreen[] | Screen[];
  values: FormSubmissionValues[];
  setValues: Dispatch<SetStateAction<FormSubmissionValues[] | null>>;
  showCorrectAnswers?: boolean;
  outcomePhase?: boolean;
};

const SubmissionEditorSteps = memo(({
  screens = [],
  values,
  setValues,
  className,
  ...otherProps
}: SubmissionEditorStepsProps) => {

  const { path } = useRouteMatch();

  return (
    <div className={`FormSubmissionEditorContent ${className || ''}`}>
      {
        screens.map((screen, index) => {

          // let's replace the last element from the path (/:page) with the page number
          const stepPath = replaceLastPathSegment(path, index + 1);

          return (
            <Route key={screen.id} path={stepPath}>
              <SubmissionEditorStep
                screen={screen}
                value={values[index]}
                setValues={(getComponentValues) => {
                  // this check is here just to satisfy the compiler
                  if (typeof getComponentValues !== 'function') return;

                  const newValues = getComponentValues(values[index]);
                  setValues((prevValues) => {
                    if (!prevValues) return null;
                    const valuesClone = [...prevValues];
                    valuesClone[index] = newValues;
                    return valuesClone;
                  });
                }}
                {...otherProps}
              />
            </Route>
          );
        })
      }
    </div>
  );
});

export default SubmissionEditorSteps;
