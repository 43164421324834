import React, { memo, useState } from 'react';
import { OverviewContent } from '@common/components/overview';
import { useAppSelector } from '@common/hooks';
import { isEmployeeOnboardingEnabled, selected } from '@modules/core/selectors/logged-user';
import Spinner from '@common/components/spinner';
import Search from '@common/components/search-bar/search';
import { isElearningEnabled } from '@modules/organisation/selectors/organisation';
import * as orgSelectors from '@modules/organisation/selectors/organisation';
import {
  useEmployeeCourses,
  useEmployeeLearning,
  useEmployeeLearningPaths,
  useMergedCourses
} from '@modules/learning/hooks/employee-academy';
import OnboardingBadge from './onboarding-badge';
import AcademyCourses from './academy-courses';
import EmployeeBadge from './employee-badge';

const EmployeeAcademyHome = memo(() => {

  const orgId = useAppSelector(orgSelectors.selected).id;
  const onboardingEnabled = useAppSelector(isEmployeeOnboardingEnabled);
  const elearningEnabled = useAppSelector(isElearningEnabled);
  const loggedUser = useAppSelector(selected);

  const { learningRes } = useEmployeeLearning(orgId);
  const { coursesRes } = useEmployeeCourses(orgId);
  const { learningPathsRes } = useEmployeeLearningPaths(orgId);

  const mergedCourses = useMergedCourses(coursesRes, learningPathsRes);

  const [search, setSearch] = useState('');

  const loading = !(learningRes && learningPathsRes && coursesRes);

  return (
    <>
      <OverviewContent className="EmployeeAcademy__TopBar">
        <EmployeeBadge
          points={learningRes ? learningRes.data.learning.current_points : null}
          loading={loading}
          loggedUser={loggedUser}
          showPoints={!onboardingEnabled || elearningEnabled}
        />
        {
          onboardingEnabled && !elearningEnabled && !loading && (
            <OnboardingBadge
              courses={mergedCourses!}
              learning={learningRes?.data}
              showCount={false}
            />
          )
        }
        {
          !loading && (
            <Search value={search} onChange={(e) => setSearch(e.target.value)} />
          )
        }
      </OverviewContent>
      <OverviewContent className="AcademyCourses">
        {
          loading ?
            <div className="EmployeeAcademy__Loading">
              <Spinner size="large" />
            </div> :
            <AcademyCourses
              courses={mergedCourses || []}
              learning={learningRes.data}
              learningPaths={learningPathsRes.data || []}
              search={search}
            />
        }
      </OverviewContent>
    </>
  );
});

export default EmployeeAcademyHome;
