import Placeholder from '@common/components/placeholder';
import AsyncTable, { TableDropdown } from '@common/components/table';
import ConfirmButton from '@common/components/confirm-button';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Api from '@common/services/api';
import FormCell from './form-cell';
import { mySubmittedFormsUrl } from './user-submissions-table';
import moment from 'moment';
import { AlertService } from '@common/services/alert';

type RowActionsProps = {
  onDelete: () => void;
  item: any;
};

export const RowActions = ({ item, onDelete }: RowActionsProps) => {
  const { t } = useTranslation();

  // String user must type to confirm deleting
  return (
    <TableDropdown>
      <ConfirmButton
        danger
        title={(
          <Trans
            i18nKey="forms:submission_draft_delete_confirm_message"
            values={{ formName: item.form.title }}
          />
        )}
        onConfirm={onDelete}
      >
        <TableDropdown.Item danger icon="delete" onClick={onDelete}>
          { t('common:delete') }
        </TableDropdown.Item>
      </ConfirmButton>
    </TableDropdown>
  );
};

type UserDraftsTableProps = {
  orgId: string;
  baseUrl: string;
};

const UserDraftsTable = memo(({ orgId, baseUrl }: UserDraftsTableProps) => {
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return [{
      label: t('forms:overview_label_name'),
    }, {
      label: t('common:last_updated_at')
    }];
  }, [t]);

  const [items, setItems] = useState<any[]>([]);

  const data = useMemo(() => {
    return {
      useCursor: true,
      onFetch: (
        nextCursor: string | null,
        filter: any,
        limit = 20
      ) => {
        const query = Api.utils.toQuery({
          limit,
          cursor: nextCursor || true,
          submission_status: 'draft'
        });
        const url = mySubmittedFormsUrl(orgId, query);
        return Api.get(url).then((res: any) => {
          setItems((prevItems: any[]) => {
            return [...prevItems, ...res.data];
          });
          return res;
        });
      },
      filter: {}
    };
  }, [orgId, setItems]);

  const renderRow = useCallback(({ item }: any) => {
    const href = `${baseUrl}/${item.form.id}/draft/${item.id}`;
    return [
      <FormCell form={item.form} href={href} />,
      moment(item.updated_at).format('MMM D, YYYY')
    ];
  }, [baseUrl]);

  const rowProps = useCallback((item: any) => {
    const base = `/v1/organisations/${orgId}`;
    return {
      async onDelete() {
        const url = `${base}/users/me/forms/submissions/${item.id}`;
        try {
          await Api.delete(url);
          const msg = t('forms:submission_draft_delete_success_message');
          AlertService.success(msg);
          setItems((prevItems: any[]) => {
            return prevItems.filter((i: any) => i.id !== item.id);
          });
        } catch (error: unknown) {
          AlertService.error(t('forms:submission_draft_delete_error_message'));
          throw error;
        }
      }
    };
  }, [orgId, t, setItems]);

  return (
    <AsyncTable
      columns={columns}
      data={data}
      items={items}
      renderRow={renderRow}
      rowProps={rowProps}
      placeholder={<Placeholder title={t('forms:no_submissions_found')} />}
      // @ts-expect-error
      ActionComponent={RowActions}
    />
  );
});

export default UserDraftsTable;
