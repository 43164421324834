import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistoryActionWithoutConfirmation } from '@common/components/confirm-button/utils';
import { AlertService } from '@common/services/alert';
import { SurveyError } from '@modules/network/definitions';

export const useSurveyEditorErrorHandling = (editorExitUrl: string) => {
  const exitEditor = useHistoryActionWithoutConfirmation(editorExitUrl);
  const { t } = useTranslation();
  return useCallback((error: any) => {
    if (error) {
      if (error.type === SurveyError.EXPIRED) {
        AlertService.error(t('survey:error_expired'));
        return exitEditor();
      }
      if (error.type === SurveyError.COMPLETED) {
        AlertService.error(t('survey:error_completed'));
        return exitEditor();
      }
      if (error.status_code === 404) {
        AlertService.error(t('survey:not_found'));
        return exitEditor();
      }
    }
    AlertService.error(t('common:something_went_wrong'));
    throw error;
  }, [exitEditor]);
};
