import React, { memo } from 'react';
import { APIEmployeeAcademyCourseOverview } from '@modules/learning/types/objects';
import CourseCard from './course-card';
import './courses-grid.scss';

type CourseGridProps = {
  title?: string;
  courses: APIEmployeeAcademyCourseOverview[];
  search?: string;
};

const CoursesGrid = memo(({ courses, title, search }: CourseGridProps) => {
  return (
    <div className="CoursesGrid">
      {
        title && (
          <h3>{ title } <span>{ courses.length }</span></h3>
        )
      }
      <div className="CoursesGrid__CoursesList">
        {
          courses.map((course) => (
            <CourseCard search={search} key={course.id} course={course} />
          ))
        }
      </div>
    </div>
  );
});

export default CoursesGrid;
