import React, { memo } from 'react';
import { normalizeText } from '../search-bar/utils';

type TextHighlightProps = {
  text: string;
  highlightText?: string;
};

const TextHighlight = memo(({ text, highlightText }: TextHighlightProps) => {
  if (highlightText) {
    // if we have a highlightText value, we want to highlight the highlightText term in the course text
    const normalizedSearch = normalizeText(highlightText);
    const index = normalizeText(text).indexOf(normalizedSearch);
    if (index > -1) {
      return (
        <>
          { text.slice(0, index) }
          <mark>{ text.slice(index, index + highlightText.length) }</mark>
          { text.slice(index + highlightText.length) }
        </>
      );
    }
  }
  return <>{text}</>;
});

export default TextHighlight;
