import React, { memo } from 'react';
import { useRouteMatch } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { APIEmployeeAcademyCourseResponse } from '@modules/learning/types/objects';
import { Button } from '@common/components/button';
import { ProgressBar } from '@common/components/progress-bar/progress-bar';
import SimpleText from '@common/components/simple-text';
import { DEFAULT_COURSE_IMAGE, getFirstAvailableModuleUrl } from '@modules/learning/utils/employee-academy';
import StatusLabel from '../home/status-label';

import './course-summary.scss';

type CourseSummaryProps = { course: APIEmployeeAcademyCourseResponse };

const CourseSummary = memo(({ course }: CourseSummaryProps) => {
  const { t } = useTranslation();
  const { progress } = course.data;
  const progressValue = (progress.finished_module_count / progress.module_count) * 100;

  // format it to 1 decimal place if we already have a decimal
  // otherwise trim the trailing 0s
  const formattedProgressValue = progressValue?.toFixed(1).replace(/[.,]0$/, '');

  const { url } = useRouteMatch();

  const backgroundImage = `url(${course.data.header_image_url}), url(${DEFAULT_COURSE_IMAGE})`;

  return (
    <div className="CourseSummary">
      <div className="CourseSummary__Header">
        <div
          className="CourseSummary__Image"
          style={{ backgroundImage }}
        >
          {
            (progress.deadline || progress.finished) && (
              <StatusLabel dueDate={progress.deadline} completed={progress.finished} />
            )
          }
        </div>
        {
          !progress.finished && getFirstAvailableModuleUrl(course, url) && (
            <Link to={getFirstAvailableModuleUrl(course, url)!}>
              <Button type="primary" size="large">
                {
                  progress.current_points > 0 ? t('learning:resume_course') : t('common:start')
                }
              </Button>
            </Link>
          )
        }
        <small>
          <Trans
            i18nKey="learning:your_progress"
            values={{ value: formattedProgressValue }}
          />
        </small>
        <ProgressBar status={progressValue} />
      </div>

      <hr />

      <div className="CourseSummary__Content">
        <h1>{course.data.name}</h1>
        <small>
          {`${progress.current_points}/${progress.max_points} ${t('common:points_full')}`}
          {
            typeof course.data.duration === 'number' && (
              ` • ${course.data.duration} ${t('common:minutes')}`
            )
          }
        </small>
        {
          course.data.description && <SimpleText text={course.data.description} />
        }
      </div>
    </div>
  );
});

export default CourseSummary;
