import React, { PropsWithChildren, useRef, useEffect, useCallback } from 'react';
import { Icon } from '../icon';
import { getMaxScrollRight, hasOverflowRight } from './utils';

import './horizontal-scroll-area.scss';

type HorizontalScrollAreaProps = PropsWithChildren<{
  className?: string;
}>;

const CARDS_SCROLL_AMOUNT = 5;

const HorizontalScrollArea = ({
  children,
  className
}: HorizontalScrollAreaProps) => {
  const container = useRef<HTMLDivElement>(null);
  const bar = useRef<HTMLDivElement>(null);
  const leftButton = useRef<HTMLDivElement>(null);
  const rightButton = useRef<HTMLDivElement>(null);

  const setButtonsVisibility = useCallback((scrollLeft: number) => {
    const loaded = bar.current && leftButton.current && rightButton.current;
    if (!loaded) return;

    if (hasOverflowRight(bar.current)) {
      leftButton.current.style.display = scrollLeft <= 0 ? 'none' : 'flex';

      const display = scrollLeft >= getMaxScrollRight(bar.current) ? 'none' : 'flex';
      rightButton.current.style.display = display;
    } else {
      rightButton.current.style.display = 'none';
      leftButton.current.style.display = 'none';
    }
  }, [bar, leftButton, rightButton]);

  useEffect(() => { // first load
    if (container.current && bar.current) {
      bar.current.style.width = `${container.current.offsetWidth}px`;
      setButtonsVisibility(bar.current.scrollLeft);
    }
  }, [container, bar, setButtonsVisibility]);

  useEffect(() => { // on resize
    const listener = () => {
      if (typeof container.current?.offsetWidth === 'number' && bar.current) {
        bar.current.style.width = `${container.current.offsetWidth}px`;
        setButtonsVisibility(bar.current.scrollLeft);
      }
    };
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [bar, container, setButtonsVisibility]);

  const scrollPositionCardIndex = useRef<number>(0);

  const handleScroll = (direction: 'left' | 'right') => {
    if (!bar.current) return;

    const cards = bar?.current?.querySelectorAll<HTMLDivElement>('.HorizontalScrollArea__Card');
    if (!cards || cards.length <= 0) return;

    let card;
    if (direction === 'right') {
      const nextIndex = scrollPositionCardIndex.current + CARDS_SCROLL_AMOUNT;
      if (cards[nextIndex]) {
        card = cards[nextIndex];
        scrollPositionCardIndex.current = nextIndex;
      } else {
        const lastIndex = cards.length - 1;
        card = cards[lastIndex];
        scrollPositionCardIndex.current = lastIndex;
      }
    } else { // scrolling left
      const previousIndex = scrollPositionCardIndex.current - CARDS_SCROLL_AMOUNT;
      if (cards[previousIndex]) {
        card = cards[previousIndex];
        scrollPositionCardIndex.current = previousIndex;
      } else {
        // eslint-disable-next-line prefer-destructuring
        card = cards[0];
        scrollPositionCardIndex.current = 0;
      }
    }

    bar.current.scrollLeft = card.offsetLeft;

    setButtonsVisibility(card.offsetLeft);
  };

  return (
    <div className={`HorizontalScrollArea ${className || ''}`} ref={container}>
      <div className="HorizontalScrollArea__Content" ref={bar}>
        <div
          ref={leftButton}
          className="HorizontalScrollArea__Chevron HorizontalScrollArea__Chevron--left"
        >
          <Icon
            size={60}
            type="chevron_left"
            onClick={() => handleScroll('left')}
          />
        </div>

        { children }

        <div
          ref={rightButton}
          className="HorizontalScrollArea__Chevron HorizontalScrollArea__Chevron--right"
        >
          <Icon
            size={60}
            type="chevron_right"
            onClick={() => handleScroll('right')}
          />
        </div>
      </div>
    </div>
  );
};

export default HorizontalScrollArea;
