import React, { useMemo } from 'react';
import { Pie, PieChart } from 'recharts';
import { Icon } from '../icon';

import './play-button.scss';

type PlayButtonProps = {
  onClick?: () => void;
  progress?: number; // decimal (from 0 to 1), indicates the progress
  progressThickness?: number;
};

const MARGIN = 1;

const PlayButton = ({ onClick, progress, progressThickness = 1 }: PlayButtonProps) => {
  const progressData = useMemo(() => {
    if (typeof progress === 'number') {
      return [
        {
          name: 'progress-line',
          value: progress,
        },
        {
          name: 'placeholder-hidden-by-css',
          value: 1 - progress,
        }
      ];
    }
    return null;
  }, [progress]);
  return (
    <div className="PlayButton" onClick={onClick}>
      {
        progressData && (
          <PieChart width={40} height={40}>
            <Pie
              data={progressData}
              startAngle={90}
              endAngle={450}
              cx="50%"
              cy="50%"
              innerRadius={20 - progressThickness + MARGIN}
              outerRadius={20}
              dataKey="value"
            />
          </PieChart>
        )
      }
      <Icon type="play_arrow" />
    </div>
  );
};

export default PlayButton;
